// import '@babel/polyfill'
import 'core-js/stable';   // Import all stable polyfills
import 'regenerator-runtime/runtime';   // Import async/await support

import 'chart.js'
import moment from 'moment'
import L from 'leaflet';
import { login, logout, forgotPassword, resetPassword, verify2fa } from './login'
import { showAlert } from './alerts'
import { createOrganisation, editOrganisation, editOrganisationDetails, createProxy, editProxy, deleteProxy, createUser, updateUser, createSupplier, createSupplierAdmin, addSupplierUserToOrg, fetchColleagueProjects } from './admin'
import { updateSettings, submitContact } from './updateSettings'
import { createProject, deleteProject, updateProject, createTarget, updateTarget, deleteTarget, createIndicator, updateIndicator, createOutput, updateOutput, createInput, updateInput, createOutputTwo, updateOutputTwo, createKpi, updateKpi, createStakeholder, addProxy, createProgramme, createInputActual, createOutcomeActual, createKpiActual, transferProject } from './project'
import { updateTargetComponent, deleteTargetComponent, duplicateTargetComponent, updateStakeholderComponent, deleteStakeholderComponent, duplicateStakeholderComponent, deleteIndicatorComponent, duplicateIndicatorComponent, updateOutputComponent, deleteOutputComponent, duplicateOutputComponent, deleteInputComponent, duplicateInputComponent, deleteOutputTwoComponent, duplicateOutputTwoComponent, deleteKpiComponent, duplicateKpiComponent, deleteProgrammeComponent, setAttributes, trimLength, duplicateProjectComponent, deleteProxyFromProjectComponent, updateLedger, getLocation, transactionFormInput, transactionFormOutcome, transactionFormKpi, fetchDataAndUpdateUI, editSupplierPopup, createNewSupplierUserForm, removeSupplierUser, fetchGeo } from './components'
import { Mapp, Input, Outcome, OutcomeActual, InputActual, Kpi, KpiActual } from './map.js';
import { getMapMarkers, removeMapMarkerByTransactionId } from './mapMarkers.js';
import { initialiseColleagues, colleagueSettings } from './myOrganisationLead';

// import { resetPassword } from '../../controllers/authController'
// import { buyLicence } from './stripe'


// DOM ELEMENTS
const contactForm = document.querySelector('.form-contact-us')
const loginForm = document.querySelector('.form--login')
const forgotPasswordForm = document.querySelector('.form--forgot')
const resetPasswordForm = document.querySelector('.form--reset-password')
const logOutBtn = document.querySelector('.nav__el--logout')
const userDataForm = document.querySelector('.form-user-data')
const userPasswordForm = document.querySelector('.form-user-password')
const createProjectForm = document.querySelector('.form-create-project')
const createProjectScope = document.querySelector('.form-project-scope')
const duplicateProjectForm = document.querySelector('.form-project-duplicate')
const deleteProjectForm = document.querySelector('.form-delete-project')
const createTargetForm = document.querySelector('.form-create-target')
const createIndicatorForm = document.querySelector('.form-create-indicator')
const createOutputForm = document.querySelector('.form-create-output')
const createInputForm = document.querySelector('.form-create-input')
const createOutputTwoForm = document.querySelector('.form-create-outputTwo')
const createKpiForm = document.querySelector('.form-create-kpi')
const createConclusionForm = document.querySelector('.form-project-conclusions')
const createStakeholderForm = document.querySelector('.form-create-stakeholder')
const addProxyForm = document.querySelector('.form-create-outcome')
const createProgrammeForm = document.querySelector('.form-create-programme')
const targets = document.querySelector('.targets')
const indicators = document.querySelector('.indicators')
const outputs = document.querySelector('.outputs')
const inputs = document.querySelector('.inputs')
const outputsTwo = document.querySelector('.outputsTwo')
const kpis = document.querySelector('.kpi')
const stakeholders = document.querySelector('.stakeholders')
const createOwnProxyForm = document.querySelector('.form-create-own-proxy')
const dropdownProxyExtras = document.querySelector('.dropdown')
// const dropbtn = document.querySelectorAll('.dropbtn')
const reportTable = document.querySelector('.report-table')
const pieChart = document.getElementById('pieChart')
const hcaDropdown = document.getElementById('hca')
const verify2faForm = document.querySelector('.form--verify2fa')

const actualInputForm = document.querySelector('.form--input')
const actualInputUpdateForm = document.querySelector('.form-update-input-actual')
const actualOutcomeForm = document.querySelector('.form--outcome')
const actualKpiForm = document.querySelector('.form--kpi')
const ledger = document.querySelector('.movements')

const outcomeTotalElement = document.getElementById('outcomeActualTotal');
const inputTotalElement = document.getElementById('inputActualTotal');
const npvElement = document.getElementById('npvLedger');
const sroiElement = document.getElementById('actualSroiRatio');

const searchText = document.getElementById('search-text')
const container = document.getElementById('proxies')
const selectedProxyBox = document.getElementById('selected-proxy-box')

const programmeContainer = document.querySelector('.programmes__card')

const comparisonCalcBtn = document.getElementById('comparison-calc')
const comparisonNumber = document.getElementById('comparisonNumber')

const projectSearchInput = document.getElementById('search-projects')
const cardContainer = document.querySelector('.card-container')
const paginationContainer = document.getElementById('paginationContainer');

const mapCanvas = document.getElementById('map');

const supplierUsers = document.getElementById('supplier-users');
const supplierUsersList = document.getElementById('suppliers-user-list');

const myOrganisationCreateUser = document.querySelector('.form-lead-create-user');
const myOrganisationUserDropdown = document.getElementById('my-organisation-users');
export { myOrganisationUserDropdown, myOrganisationCreateUser };
const colleagueSettingsForm = document.querySelector('.form-colleague-data');
export { colleagueSettingsForm };

// ADMIN
const createOrganisationForm = document.querySelector('.form-create-organisation')
const editOrganisationForm = document.querySelector('.form-edit-organisation')
const sortOrganisationButton = document.querySelector('.btn--sort__org')
const createSupplierAdminForm = document.querySelector('.form-create-admin-supplier')
const editProxyForm = document.querySelector('.form-edit-proxy')
const createProxyForm = document.querySelector('.form-create-proxy')
const deleteProxyForm = document.querySelector('.form-delete-proxy')
const editUserForm = document.querySelector('.form-edit-user')

const createUserForm = document.querySelector('.form-create-user')
const createSupplierForm = document.querySelector('.form-create-supplier')
const searchUsers = document.getElementById('search-users')
const userContainer = document.getElementById('allusers')
// const bookBtn = document.getElementById('buy-licence')
const orgEditFormContainer = document.getElementById('org-edit-container');

// SLIDERS
let yearsDisplay = document.getElementById('yearsValue')
let yearsRange = document.getElementById('years')

let leakageDisplay = document.getElementById('leakageValue')
let leakageRange = document.getElementById('leakage')

let deadweightDisplay = document.getElementById('deadweightValue')
let deadweightRange = document.getElementById('deadweight')

let attributionDisplay = document.getElementById('attributionValue')
let attributionRange = document.getElementById('attribution')

let displacementDisplay = document.getElementById('displacementValue')
let displacementRange = document.getElementById('displacement')

let dropOffDisplay = document.getElementById('dropOffValue')
let dropOffRange = document.getElementById('dropOff')

const DGusers = ['5c8a1e1a2f8fb814b56fa182', '6041ebb26c33723fa108ea42', '5fc10e6718f2ac6244e3e9c9', '5fc25f7218f2ac6244e3eb21', '5fc2619718f2ac6244e3eb2a', '5fc2649218f2ac6244e3eb37', '5fc6385d18f2ac6244e3ed17', '5fc6390718f2ac6244e3ed18', '614380beb6688f581869ee05', '614381a4b6688f581869ee06', '61f770dc29e7a82f6cfa6e1a']


// If the target section exists, we expose the project object from the template to the front-end js (this), we for each over the targets to create the 'buttons', set the attributes. Then on button click, we open the modal and populate that. Then on submit we call the API function. 
if (targets) {
    const myTargets = document.getElementById('myTargets')
    const modal = document.getElementById('targetModal')

    project.targets.forEach(el => {
        const button = document.createElement('div')
        const edit = document.createElement('button')
        const deleteTargetButton = document.createElement('button')
        const duplicateTargetButton = document.createElement('button')
        const spando = document.createElement('span')
        spando.className = 'review-span'
        edit.className = 'btn-account btn--small btn--green btn--update-target'
        duplicateTargetButton.className = 'btn-account btn--small btn--green btn--duplicate-target'
        deleteTargetButton.className = 'btn-account btn--small btn--red btn--delete-target'
        edit.innerHTML = '<svg class="review__icon"><use xlink:href="/img/icons.svg#icon-edit"></use></svg>'
        deleteTargetButton.innerHTML = '<svg class="review__icon"><use xlink:href="/img/icons.svg#icon-delete"></use></svg>'
        duplicateTargetButton.innerHTML = '<svg class="review__icon"><use xlink:href="/img/icons.svg#icon-copy"></use></svg>'

        const targetTrim = trimLength(el.title)
        // Create the individual boxes for the targets
        button.className = 'reviews__card'
        button.innerHTML = `<div class='reviews__avatar'><h6 class='reviews__user'>${targetTrim}</h6></div><p class='reviews__text'>${el.targetFulfilled.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} of ${el.targetForecast.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p><div class='reviews__rating'>${el.fulfillPercentage.toFixed()}%</div><progress max=100 value=${el.fulfillPercentage}>${el.fulfillPercentage.toFixed()}%</progress>`

        // Add the buttons to the box
        button.appendChild(spando)
        spando.appendChild(edit)
        spando.appendChild(duplicateTargetButton)
        spando.appendChild(deleteTargetButton)

        setAttributes(edit, {
            'data-key': el._id,
            'data-title': el.title,
            'data-targetDescription': el.targetDescription,
            'data-targetCategory': el.targetCategory,
            'data-targetfulfilled': el.targetFulfilled,
            'data-targetforecast': el.targetForecast
        })

        setAttributes(deleteTargetButton, {
            'data-key': el._id
        })

        setAttributes(duplicateTargetButton, {
            'data-key': el._id,
            'data-project': el.project,
            'data-title': el.title,
            'data-targetDescription': el.targetDescription,
            'data-targetCategory': el.targetCategory,
            'data-targetfulfilled': el.targetFulfilled,
            'data-targetforecast': el.targetForecast
        })

        myTargets.appendChild(button)

        // delete target button
        deleteTargetButton.addEventListener('click', deleteTargetComponent)

        // duplicate target button
        duplicateTargetButton.addEventListener('click', duplicateTargetComponent)

        edit.addEventListener('click', (event) => {
            const key = event.target.getAttribute('data-key')
            const title = event.target.getAttribute('data-title')
            const targetDescriptionAttribute = event.target.getAttribute('data-targetDescription')
            const targetCategoryAttribute = event.target.getAttribute('data-targetCategory')
            const targetFulfilledAttribute = event.target.getAttribute('data-targetFulfilled')
            const targetForecastAttribute = event.target.getAttribute('data-targetforecast')

            const targetCategories = ['inputs', 'outputs', 'indicators', 'thresholds for deadweight'];

            const categoryDropdownFunction = (categoryArr, categoryAttribute) => {
                let options = "<option value=''>None</option>";
                // return `<option>${categoryAttribute}</option><option>${categoryAttribute}</option>`;
                categoryArr.forEach((category) => {
                    // console.log(category)
                    // console.log(categoryAttribute)
                    if (category === categoryAttribute) {
                        // options = options + `<option selected='selected' value='${category}'>${category}</option>`;
                        options = options + `<option selected='selected' value='${category}'>${category[0].toUpperCase() + category.substring(1)}</option>`;
                    } else {
                        options = options + `<option value='${category}'>${category[0].toUpperCase() + category.substring(1)}</option>`;
                    }
                })
                return options;
            }

            modal.style.display = 'block'

            modal.innerHTML = `<div class='modal__content-small'>
                                <div class='modal--create__left'>
                                    <h2 class='heading-account u-margin-bottom-small'>Update Target</h2>
                                            <form class='form form-update-target'>
                                            <div class='form-account__group'>
                                                <input id='targetId' value=${key} type='hidden'>
                                                <div class='row'>
                                                <div class='form-account__group ma-bt-sm'><label class='form-account__label' for='titleUpdate'>Name</label><input id='titleUpdate' class='form-account__input' type='text' name='titleUpdate' value='${title}'></div>
                                                </div>
                                                <div class='row'>
                                                <div class='form-account__group ma-bt-sm'><label class='form-account__label' for='descriptionUpdate'>Description</label><input id='targetUpdateDescription' class='form-account__input' type='text' name='descriptionUpdate' value='${targetDescriptionAttribute}'></div>
                                                </div>
                                                <div class='row'>
                                                <div class='form-account__group ma-bt-sm'><label class='form-account__label' for='categoryUpdate'>Category</label>
                                                <select id='targetUpdateCategory' class='form-account__input'>
                                                ${categoryDropdownFunction(targetCategories, targetCategoryAttribute)}
                                                </select>
                                                </div>
                                                </div>
                                                </div>
                                                <div class='row'>
                                                <div class='col-1-of-2'>
                                                <div class='form-account__group ma-bt-sm'><label class='form-account__label' for='fulfilledUpdate'>Fulfilled</label><input id='targetUpdateFulfilled' class='form-account__input' type='number' name='fulfilledUpdate' value=${targetFulfilledAttribute}></div>
                                                </div>
                                                <div class='col-1-of-2'>
                                                <div class='form-account__group ma-bt-sm'><label class='form-account__label' for='fulfilledForecast'>Of</label><input id='targetUpdateForecast' class='form-account__input' type='number' name='fulfilledForecast' value=${targetForecastAttribute}></div>
                                                </div>
                                                </div>
                                                <div class='modal--create__right'><a class='modal__close' href=''>&times;</a></div>
                                                <div class='form-account__group right'>
                                                <button class='btn-account btn--small btn--green btn--update-target' type='submit'>Update Target</button>
                                                
                                                </div>
                                                </div>
                                            </form>
                                        </div>`

            let updateTargetForm = document.querySelector('.form-update-target')

            // document.addEventListener('click', (e) => {
            //     console.log(e.target.className);
            //     if (modal.style.display == 'block' && e.target.class !== "modal__content-small") {
            //         modal.style.display = 'none'
            //     }
            // });

            document.querySelector('.modal__close').addEventListener('click', (ev) => {
                ev.preventDefault()
                modal.style.display = 'none'
            });

            // If the update Target form is submitted, send to API
            if (updateTargetForm) {
                updateTargetForm.addEventListener('submit', updateTargetComponent)
            }
        })
        // Add the targets to the myTargets element in the DOM
        // myTargets.appendChild(button)
    })
}

if (indicators) {
    const myIndicators = document.getElementById('myIndicators')
    const modal = document.getElementById('indicatorModal')

    project.indicators.forEach(el => {
        const button = document.createElement('div')

        const edit = document.createElement('button')
        const deleteIndicatorButton = document.createElement('button')
        const duplicateIndicatorButton = document.createElement('button')
        const spando = document.createElement('span')
        spando.className = 'review-span'
        edit.className = 'btn-account btn--small btn--green btn--update-target'
        duplicateIndicatorButton.className = 'btn-account btn--small btn--green btn--duplicate-target'
        deleteIndicatorButton.className = 'btn-account btn--small btn--red btn--delete-target'
        edit.innerHTML = '<svg class="review__icon"><use xlink:href="/img/icons.svg#icon-edit"></use></svg>'
        deleteIndicatorButton.innerHTML = '<svg class="review__icon"><use xlink:href="/img/icons.svg#icon-delete"></use></svg>'
        duplicateIndicatorButton.innerHTML = '<svg class="review__icon"><use xlink:href="/img/icons.svg#icon-copy"></use></svg>'

        const indicatorTrim = trimLength(el.title)
        button.className = 'reviews__card'
        button.innerHTML = `<div class='reviews__avatar'><h6 class='reviews__user'>${indicatorTrim}</h6></div><p class='reviews__text'></p><div class='reviews__rating'>${el.subjective}</div>`

        // Add the buttons to the box
        button.appendChild(spando)
        spando.appendChild(edit)
        spando.appendChild(duplicateIndicatorButton)
        spando.appendChild(deleteIndicatorButton)

        setAttributes(edit, {
            'data-key': el._id,
            'data-title': JSON.stringify(el.title),
            'data-description': JSON.stringify(el.description),
            'data-subjective': el.subjective,
            'data-measurementUnit': JSON.stringify(el.measurementUnit),
            'data-benchmark': JSON.stringify(el.benchmark),
            'data-baseline': JSON.stringify(el.baseline),
            'data-measurement': JSON.stringify(el.measurement),
            'data-indicatorURL': el.indicatorURL,
            'data-indicatorQuantity': el.indicatorQuantity
        })

        setAttributes(deleteIndicatorButton, {
            'data-key': el._id
        })

        setAttributes(duplicateIndicatorButton, {
            'data-project': el.project,
            'data-title': el.title,
            'data-description': el.description,
            'data-subjective': el.subjective,
            'data-measurementUnit': el.measurementUnit,
            'data-benchmark': el.benchmark,
            'data-baseline': el.baseline,
            'data-measurement': el.measurement,
            'data-indicatorURL': el.indicatorURL,
            'data-indicatorQuantity': el.indicatorQuantity,
            'data-outcomeId': el.outcomeId
        })

        // delete indicator button
        deleteIndicatorButton.addEventListener('click', deleteIndicatorComponent)

        // duplicate input button
        duplicateIndicatorButton.addEventListener('click', duplicateIndicatorComponent)

        edit.addEventListener('click', (event) => {
            const key = event.target.getAttribute('data-key')
            const title = event.target.getAttribute('data-title')
            const descriptionAttribute = event.target.getAttribute('data-description')
            const subjectiveAttribute = event.target.getAttribute('data-subjective')
            const measurementUnitAttribute = event.target.getAttribute('data-MeasurementUnit')
            const benchmarkAttribute = event.target.getAttribute('data-benchmark')
            const baselineAttribute = event.target.getAttribute('data-baseline')
            const measurementAttribute = event.target.getAttribute('data-measurement')
            const indicatorURLAttribute = event.target.getAttribute('data-indicatorURL')
            const indicatorQuantityAttribute = event.target.getAttribute('data-indicatorQuantity')

            modal.style.display = 'block'
            // modal.style.visibility = 'visible'

            modal.innerHTML = `<div class='modal__content-small'>
                                <div class='modal--create__left'>
                                    <h2 class='heading-account u-margin-bottom-small'>Update Indicator</h2>
                                            <form class='form form-update-indicator'>
                                            <div class='form-account__group'>
                                                <input id='indicatorId' value=${key} type='hidden'>
                                                <div class='row'>
                                                <div class='col-1-of-2'>
                                                <div class='form-account__group ma-bt-sm'><label class='form-account__label' for='titleUpdate'>Name</label><input id='titleUpdate' class='form-account__input' type='text' name='titleUpdate' value=${title}></div>
                                                </div>
                                                <div class='col-1-of-2'>
                                                <div class='form-account__group ma-bt-sm'><label class='form-account__label' for='descriptionUpdate'>Description</label><input id='descriptionUpdate' class='form-account__input' type='text' name='descriptionUpdate' value=${descriptionAttribute}></div>
                                                </div>
                                                </div>
                                                <div class='row'>
                                                <div class='col-1-of-2'>
                                                <div class='form-account__group ma-bt-sm'><label class='form-account__label' for='measurementUnitUpdate'>Measurement Unit</label><input id='measurementUnitUpdate' class='form-account__input' type='text' name='measurementUnitUpdate' value=${measurementUnitAttribute}></div>
                                                </div>
                                                <div class='col-1-of-2'>
                                                <div class='form-account__group ma-bt-sm'><label class='form-account__label' for='benchmarkUpdate'>Benchmark</label><input id='benchmarkUpdate' class='form-account__input' type='text' name='benchmarkUpdate' value=${benchmarkAttribute}></div>
                                                </div>
                                                </div>
                                                <div class='row'>
                                                <div class='col-1-of-2'>
                                                <div class='form-account__group ma-bt-sm'><label class='form-account__label' for='baselineUpdate'>Baseline</label><input id='baselineUpdate' class='form-account__input' type='text' name='baselineUpdate' value=${baselineAttribute}></div>
                                                </div>
                                                <div class='col-1-of-2'>
                                                <div class='form-account__group ma-bt-sm'><label class='form-account__label' for='indicatorQuantityUpdate'>Quantity</label><input id='indicatorQuantityUpdate' class='form-account__input' type='text' name='indicatorQuantityUpdate' value=${indicatorQuantityAttribute}></div>
                                                </div>
                                                </div>
                                                <div class='row'>
                                                <div class='col-1-of-2'>
                                                <div class='form-account__group ma-bt-sm'><label class='form-account__label' for='measurementUpdate'>Measurement Method</label><input id='measurementUpdate' class='form-account__input' type='text' name='measurementUpdate' value=${measurementAttribute}></div>
                                                </div>
                                                <div class='col-1-of-2'>
                                                <div class='form-account__group ma-bt-sm'><label class='form-account__label' for='indicatorURLUpdate'>Source URL</label><input id='indicatorURLUpdate' class='form-account__input' type='url' name='indicatorURLUpdate' value=${indicatorURLAttribute}></div>
                                                </div>
                                                </div>
                                                <a class='modal__close' href=''>&times;</a>
                                                <div class='form-account__group right'>
                                                <button class='btn-account btn--small btn--green btn--update-indicator' type='submit'>Update Indicator</button>
                                                </div>
                                                </div>
                                            </form>
                                        </div>`

            let updateIndicatorForm = document.querySelector('.form-update-indicator')

            document.querySelector('.modal__close').addEventListener('click', (ev) => {
                ev.preventDefault()
                modal.style.display = 'none'
                // modal.style.visibility = 'hidden'
            })

            // If the update Indicator form is submitted, send to API
            if (updateIndicatorForm) {
                updateIndicatorForm.addEventListener('submit', async (e) => {
                    e.preventDefault()
                    const indicatorId = document.getElementById('indicatorId').value
                    const title = document.getElementById('titleUpdate').value
                    const description = document.getElementById('descriptionUpdate').value
                    const measurementUnit = document.getElementById('measurementUnitUpdate').value
                    const benchmark = document.getElementById('benchmarkUpdate').value
                    const baseline = document.getElementById('baselineUpdate').value
                    const indicatorQuantity = document.getElementById('indicatorQuantityUpdate').value
                    const measurement = document.getElementById('measurementUpdate').value
                    const indicatorURL = document.getElementById('indicatorURLUpdate').value
                    // const volume = document.getElementById('volumeUpdate').value
                    // const value = document.getElementById('valueUpdate').value

                    await updateIndicator(indicatorId, { title, description, measurementUnit, benchmark, baseline, indicatorQuantity, measurement, indicatorURL })

                    document.querySelector('.btn--update-indicator').textContent = 'Updating...'
                    window.setTimeout(() => {
                        location.reload()
                    }, 1000)
                })
            }
        })
        // Add the indicators to the myIndicators element in the DOM
        myIndicators.appendChild(button)
    })
}

if (outputs) {
    const myOutputs = document.getElementById('myOutputs')
    const modal = document.getElementById('outputModal')

    let outputCurrency
    if (project.currency) {
        outputCurrency = project.currency
    } else {
        outputCurrency = '£'
    }

    project.outputs.forEach(el => {
        const button = document.createElement('div')
        const edit = document.createElement('button')
        const deleteOutputButton = document.createElement('button')
        const duplicateOutputButton = document.createElement('button')
        const spando = document.createElement('span')
        spando.className = 'review-span'
        edit.className = 'btn-account btn--small btn--green btn--update-target'
        duplicateOutputButton.className = 'btn-account btn--small btn--green btn--duplicate-target'
        deleteOutputButton.className = 'btn-account btn--small btn--red btn--delete-target'
        edit.innerHTML = '<svg class="review__icon"><use xlink:href="/img/icons.svg#icon-edit"></use></svg>'
        deleteOutputButton.innerHTML = '<svg class="review__icon"><use xlink:href="/img/icons.svg#icon-delete"></use></svg>'
        duplicateOutputButton.innerHTML = '<svg class="review__icon"><use xlink:href="/img/icons.svg#icon-copy"></use></svg>'

        const outputTrim = trimLength(el.title)
        button.className = 'reviews__card'
        button.innerHTML = `<div class='reviews__avatar'><h6 class='reviews__user'>${outputTrim}</h6></div><p class='reviews__text'></p><div class='reviews__rating'>Impact: ${outputCurrency}${((el.impact).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))}</div>`

        // Add the buttons to the box
        button.appendChild(spando)
        spando.appendChild(edit)
        spando.appendChild(duplicateOutputButton)
        spando.appendChild(deleteOutputButton)
        // check if geocode exists
        let geocode;
        let lat;
        let lng;
        if (el.geocode) {
            geocode = el.geocode.locationId
            lat = el.geocode.lat
            lng = el.geocode.lng
        } else {
            geocode = undefined;
        }
        setAttributes(edit, {
            'data-key': el._id,
            'data-title': JSON.stringify(el.title),
            // 'data-indicators': JSON.stringify(el.indicators),
            'data-userOutcome': JSON.stringify(el.userOutcome),
            'data-deflatorDescription': JSON.stringify(el.deflatorDescription),
            'data-ownFramework': el.ownFramework,
            'data-quantity': el.quantity,
            'data-timePeriod': el.timePeriod,
            'data-leakage': el.leakage,
            'data-deadweight': el.deadweight,
            'data-attribution': el.attribution,
            'data-displacement': el.displacement,
            'data-dropOff': el.dropOff,
            'data-startDate': el.startDate,
            // 'data-startDate': moment(el.startDate).format("YYYY-MM-DD"),
            // 'data-startDate': Date(el.startDate),
            'data-endDate': el.endDate,
            // 'data-endDate': Date(el.endDate)
            'data-geocode': geocode,
            'data-location-id': geocode,
            'data-lat': lat,
            'data-lng': lng
        })
        setAttributes(deleteOutputButton, {
            'data-key': el._id
        })
        setAttributes(duplicateOutputButton, {
            'data-project': el.project,
            'data-title': el.title,
            // 'data-indicators': el.indicators,
            'data-userOutcome': el.userOutcome,
            'data-deflatorDescription': el.deflatorDescription,
            'data-quantity': el.quantity,
            'data-timePeriod': el.timePeriod,
            'data-leakage': el.leakage,
            'data-deadweight': el.deadweight,
            'data-attribution': el.attribution,
            'data-displacement': el.displacement,
            'data-dropOff': el.dropOff,
            'data-value': el.value,
            'data-bristol': el.bristol,
            'data-sdg': el.sdg,
            'data-dg': el.dg,
            'data-barns': el.barns,
            'data-proxyTitle': el.proxyTitle,
            'data-outcome': el.outcome,
            'data-unit': el.unit,
            'data-anchor': el.anchor,
            'data-source': el.source,
            'data-stakeholder': el.stakeholder,
            'data-afterDuring': el.afterDuring,
            'data-positiveNegative': el.positiveNegative,
            'data-startDate': el.startDate,
            'data-endDate': el.endDate
        })

        // delete output button
        deleteOutputButton.addEventListener('click', deleteOutputComponent)

        // duplicate output button
        duplicateOutputButton.addEventListener('click', duplicateOutputComponent)

        edit.addEventListener('click', (event) => {
            const key = event.target.getAttribute('data-key')
            const title = event.target.getAttribute('data-title')
            const userOutcomeAttribute = event.target.getAttribute('data-userOutcome')
            // const startDateAttribute = moment(event.target.getAttribute('data-startDate')).format('YYYY-MM-DD')
            const startDateAttribute = event.target.getAttribute('data-startDate')
            const endDateAttribute = event.target.getAttribute('data-endDate')
            const deflatorDescriptionAttribute = event.target.getAttribute('data-deflatorDescription')
            const ownFrameworkAttribute = event.target.getAttribute('data-ownFramework')
            const quantityAttribute = event.target.getAttribute('data-quantity')
            const timePeriodAttribute = event.target.getAttribute('data-timePeriod')
            const leakageAttribute = event.target.getAttribute('data-leakage')
            const deadweightAttribute = event.target.getAttribute('data-deadweight')
            const attributionAttribute = event.target.getAttribute('data-attribution')
            const displacementAttribute = event.target.getAttribute('data-displacement')
            const dropOffAttribute = event.target.getAttribute('data-dropOff')
            const locationId = event.target.getAttribute('data-geocode')
            const lat = event.target.getAttribute('data-lat')
            const lng = event.target.getAttribute('data-lng')
            const ownFrameworkOptions = formattedFrameworkOptions;
            let inputBox;
            const createDropdownOptionsHTML = (options = [], selectedValue) => {
                if (!Array.isArray(options)) {
                    console.error('Expected options to be an array but received:', options);
                    options = [];
                }
                let optionsHTML = '';
                for (const option of options) {
                    const isSelected = Number(option._id) === Number(selectedValue) ? 'selected' : '';
                    optionsHTML += `<option value='${option._id}' ${isSelected}>${option.description}</option>`;
                }
                return optionsHTML;
            };
            if (project.framework === 'own_framework') {
                inputBox = `<label class='form-account__label' for='ownFrameworkUpdate'>Framework Priority</label>
                <select id='ownFrameworkUpdate' class='form-account__input' name='ownFrameworkUpdate'>${createDropdownOptionsHTML(ownFrameworkOptions, ownFrameworkAttribute)}</select>`
            } else {
                inputBox = `<label class='form-account__label' for='deflatorDescriptionUpdate'> Description of Deflators</label>
                    <input id='deflatorDescriptionUpdate' class='form-account__input' type='text' name='deflatorDescriptionUpdate' value=${deflatorDescriptionAttribute}>`
            }
            let geoBox;
            // if (locationId === 'undefined') {
            if (document.getElementById('location')) {
                geoBox = `<label class='form-account__label' for='geographyUpdate'>Geography</label>
                    <input id='geographyUpdate' class='form-account__input' type='text' name='geographyUpdate'><div id="popup-location-container" class="outcome-location-list"></div>`
            } else {
                geoBox = `<div class='col-1-of-2'>
                    <label class='form-account__label' for='startDate'>Start Date</label>
                    <input id='startDateUpdate' class='form-account__input' type='date' name='startDateUpdate' value=${moment(startDateAttribute).format('YYYY-MM-DD')}>
                </div>
                <div class='col-1-of-2'>
                    <label class='form-account__label' for='endDate'>End Date</label>
                    <input id='endDateUpdate' class='form-account__input' type='date' name='endDateUpdate' value=${moment(endDateAttribute).format('YYYY-MM-DD')}></div>`
            }
            modal.style.display = 'block'

            modal.innerHTML = `<div class='modal__content-output'>
                            <div class='modal--create__left'>
                                <h2 class='heading-account u-margin-bottom-small'>Update Outcome</h2>
                                <form class='form form-update-output'>
                                    <div class='form-account__group'>
                                        <input id='outputId' value=${key} type='hidden'>
                                            <div class='row'>
                                                <div class='col-1-of-2'>
                                                    <label class='form-account__label' for='titleUpdate'>Outcome Title</label>
                                                    <input id='titleUpdate' class='form-account__input' type='text' name='titleUpdate' value=${title}>
                                                </div>
                                                <div class='col-1-of-2'>
                                                    <label class='form-account__label' for='userOutcomeUpdate'>User Outcome</label>
                                                    <input id='userOutcomeUpdate' class='form-account__input' type='text' name='userOutcomeUpdate' value=${userOutcomeAttribute}>
                                                </div>
                                            </div>
                                            <div class='row'>
                                                <div class='col-1-of-2'>
                                                    ${geoBox}
                                                </div>
                                                <div class='col-1-of-2'>
                                                    ${inputBox}
                                                </div>
                                            </div>
                                            <div class='row'>
                                                <div class='col-1-of-3'>
                                                    <div class='form-account__group ma-bt-sm'><label class='form-account__label' for='quantityUpdate'>Quantity</label><input id='quantityUpdate' class='form-account__input' type='number' step='0.1' name='quantityUpdate' value=${quantityAttribute}></div>
                                                </div>
                                                <div class='col-2-of-3'>
                                                    <div class='form-account__group ma-bt-sm'><label class='form-account__label-years' for='timePeriodUpdate'>Years: <span id='timePeriodUpdateValue'>${timePeriodAttribute}</span></label><input id='timePeriodUpdate' class='form-account__longrange' type='range', min='0' max='15' value=${timePeriodAttribute} name='timePeriodUpdate'></div>
                                                </div>
                                            </div>

                                            <div class='row'>
                                                <div class='col-1-of-2'>
                                                    <div class='form-account__group ma-bt-sm'><label class='form-account__label' for='leakageUpdate'>Leakage: <span id='leakageUpdateValue'>${leakageAttribute}%</span></label><input id='leakageUpdate' class='form-account__longrange' type='range', min='0' max='100' name='leakageUpdate' value=${leakageAttribute}></div>
                                                    <div class='form-account__group ma-bt-sm'><label class='form-account__label' for='attributionUpdate'>Attribution: <span id='attributionUpdateValue'>${attributionAttribute}%</span></label><input id='attributionUpdate' class='form-account__longrange' type='range', min='0' max='100' value='${attributionAttribute}' name='attributionUpdate'></div>
                                                    <div class='form-account__group ma-bt-md'><label class='form-account__label' for='displacementUpdate'>Displacement: <span id='displacementUpdateValue'>${displacementAttribute}%</span></label><input id='displacementUpdate' class='form-account__longrange' type='range', min='0' max='100' value='${displacementAttribute}' name='displacementUpdate'></div>
                                                </div>

                                                <div class='col-1-of-2'>
                                                    <div class='form-account__group ma-bt-sm'><label class='form-account__label' for='deadweightUpdate'>Deadweight: <span id='deadweightUpdateValue'>${deadweightAttribute}%</span></label><input id='deadweightUpdate' class='form-account__longrange' type='range', min='0' max='100' value=${deadweightAttribute}></div>
                                                    <div class='form-account__group ma-bt-lg'><label class='form-account__label' for='dropOffUpdate'>Drop-off: <span id='dropOffUpdateValue'>${dropOffAttribute}%</span></label><input id='dropOffUpdate' class='form-account__longrange' type='range', min='0' max='100' name='dropOffUpdate' value=${dropOffAttribute}></div>

                                                    <a class='modal__close' href=''>&times;</a>
                                                    <div class='form-account__group right'>
                                                        <button class='btn-account btn--small btn--green btn--update-output' type='submit'>Update Outcome</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>`

            let updateOutputForm = document.querySelector('.form-update-output')

            document.querySelector('.modal__close').addEventListener('click', (ev) => {
                ev.preventDefault();
                modal.style.display = 'none'
            })
            const geographyUpdateElement = document.getElementById('geographyUpdate');
            const geographyUpdateContainer = document.querySelector("#popup-location-container");

            if (geographyUpdateElement) {
                // if the locationId exists, fetch the correct geographical data from the api
                if (locationId && locationId !== 'undefined' && locationId !== 'undefined' && locationId !== null && locationId !== 'null') {
                    try {
                        fetchGeo(locationId)
                            .then((geoDescrip) => {
                                // Use geoDescrip here
                                if (geographyUpdateElement) {
                                    geographyUpdateElement.value = geoDescrip;
                                    geographyUpdateElement.setAttribute('data-location-id', locationId);
                                    geographyUpdateElement.setAttribute('data-lat', lat);
                                    geographyUpdateElement.setAttribute('data-lng', lng);
                                    // Attach event listener when the element is available
                                    geographyUpdateElement.addEventListener('input', () => {
                                        getLocation(geographyUpdateElement, geographyUpdateContainer, {
                                            dropdownClass: "input-actual-dropdown",
                                            listClass: "input-actual-dropdown-ul",
                                            itemClass: "input-actual-dropdown-item"
                                        });
                                    });
                                }
                            })
                            .catch((err) => {
                                console.log(err)
                            });
                    } catch (err) {
                        console.error(err);
                    }
                } else {
                    // If no data is available, leave the input box blank and attach event listener
                    geographyUpdateElement.addEventListener('input', () => {
                        const inputValue = geographyUpdateElement.value;
                        if (inputValue.trim() !== '') {
                            getLocation(geographyUpdateElement, geographyUpdateContainer, {
                                dropdownClass: "input-actual-dropdown",
                                listClass: "input-actual-dropdown-ul",
                                itemClass: "input-actual-dropdown-item"
                            });
                        }
                    });
                }
            }

            let timePeriodDisplayUpdate = document.getElementById('timePeriodUpdateValue')
            let timePeriodRangeUpdate = document.getElementById('timePeriodUpdate')

            let leakageDisplayUpdate = document.getElementById('leakageUpdateValue')
            let leakageRangeUpdate = document.getElementById('leakageUpdate')

            let deadweightDisplayUpdate = document.getElementById('deadweightUpdateValue')
            let deadweightRangeUpdate = document.getElementById('deadweightUpdate')

            let attributionDisplayUpdate = document.getElementById('attributionUpdateValue')
            let attributionRangeUpdate = document.getElementById('attributionUpdate')

            let displacementDisplayUpdate = document.getElementById('displacementUpdateValue')
            let displacementRangeUpdate = document.getElementById('displacementUpdate')

            let dropOffDisplayUpdate = document.getElementById('dropOffUpdateValue')
            let dropOffRangeUpdate = document.getElementById('dropOffUpdate')

            timePeriodRangeUpdate.oninput = function () {
                timePeriodDisplayUpdate.innerHTML = this.value
            }

            leakageRangeUpdate.oninput = function () {
                leakageDisplayUpdate.innerHTML = this.value + '%'
            }

            deadweightRangeUpdate.oninput = function () {
                deadweightDisplayUpdate.innerHTML = this.value + '%'
            }

            attributionRangeUpdate.oninput = function () {
                attributionDisplayUpdate.innerHTML = this.value + '%'
            }

            displacementRangeUpdate.oninput = function () {
                displacementDisplayUpdate.innerHTML = this.value + '%'
            }

            dropOffRangeUpdate.oninput = function () {
                dropOffDisplayUpdate.innerHTML = this.value + '%'
            }

            // If the update Output form is submitted, send to API
            if (updateOutputForm) {
                updateOutputForm.addEventListener('submit', updateOutputComponent)
            }
        })
        // Add the targets to the myTargets element in the DOM
        myOutputs.appendChild(button)
    })

    // SLIDERS
    yearsDisplay.innerHTML = yearsRange.value
    leakageDisplay.innerHTML = leakageRange.value + '%'
    deadweightDisplay.innerHTML = deadweightRange.value + '%'
    attributionDisplay.innerHTML = attributionRange.value + '%'
    displacementDisplay.innerHTML = displacementRange.value + '%'
    dropOffDisplay.innerHTML = dropOff.value + '%'

    // On change update the html of the span/label - put this all in one function?
    yearsRange.oninput = function () {
        yearsDisplay.innerHTML = this.value
    }

    leakageRange.oninput = function () {
        leakageDisplay.innerHTML = this.value + '%'
    }

    deadweightRange.oninput = function () {
        deadweightDisplay.innerHTML = this.value + '%'
    }

    attributionRange.oninput = function () {
        attributionDisplay.innerHTML = this.value + '%'
    }

    displacementRange.oninput = function () {
        displacementDisplay.innerHTML = this.value + '%'
    }

    dropOffRange.oninput = function () {
        dropOffDisplay.innerHTML = this.value + '%'
    }
}

if (inputs) {
    const myInputs = document.getElementById('myInputs')
    const modal = document.getElementById('inputModal')
    // console.log(project.inputs);
    let inputCurrency
    if (project.currency) {
        inputCurrency = project.currency
    } else {
        inputCurrency = '£'
    }
    project.inputs.forEach(el => {
        const button = document.createElement('div')

        const edit = document.createElement('button')
        const deleteInputButton = document.createElement('button')
        const duplicateInputButton = document.createElement('button')
        const spando = document.createElement('span')
        spando.className = 'review-span'
        edit.className = 'btn-account btn--small btn--green btn--update-target'
        duplicateInputButton.className = 'btn-account btn--small btn--green btn--duplicate-target'
        deleteInputButton.className = 'btn-account btn--small btn--red btn--delete-target'
        edit.innerHTML = '<svg class="review__icon"><use xlink:href="/img/icons.svg#icon-edit"></use></svg>'
        deleteInputButton.innerHTML = '<svg class="review__icon"><use xlink:href="/img/icons.svg#icon-delete"></use></svg>'
        duplicateInputButton.innerHTML = '<svg class="review__icon"><use xlink:href="/img/icons.svg#icon-copy"></use></svg>'

        const inputTrim = trimLength(el.title)
        button.className = 'reviews__card'
        button.innerHTML = `<div class='reviews__avatar'><h6 class='reviews__user'>${inputTrim}</h6></div><p class='reviews__text'></p><div class='reviews__rating'>Cost: ${inputCurrency}${((el.impact).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))}</div>`

        // Add the buttons to the box
        button.appendChild(spando)
        spando.appendChild(edit)
        spando.appendChild(duplicateInputButton)
        spando.appendChild(deleteInputButton)
        let geocode;
        let lat;
        let lng;
        if (el.geocode) {
            geocode = el.geocode.locationId
            lat = el.geocode.lat
            lng = el.geocode.lng
        } else {
            geocode = undefined;
        }
        setAttributes(edit, {
            'data-key': el._id,
            'data-title': JSON.stringify(el.title),
            'data-volume': el.volume,
            'data-value': el.value,
            'data-category': JSON.stringify(el.category),
            'data-stakeholder': JSON.stringify(el.stakeholder),
            'data-inputToActivity': JSON.stringify(el.inputToActivity),
            // 'data-inputNotes': JSON.stringify(el.inputNotes)
            'data-geocode': geocode,
            'data-location-id': geocode,
            'data-lat': lat,
            'data-lng': lng
        })
        setAttributes(deleteInputButton, {
            'data-key': el._id
        })

        setAttributes(duplicateInputButton, {
            'data-project': el.project,
            'data-title': el.title,
            'data-volume': el.volume,
            'data-value': el.value,
            'data-category': el.category,
            'data-stakeholder': el.stakeholder,
            'data-inputToActivity': el.inputToActivity
            // 'data-inputNotes': el.inputNotes
        })

        // delete input button
        deleteInputButton.addEventListener('click', deleteInputComponent)

        // duplicate input button
        duplicateInputButton.addEventListener('click', duplicateInputComponent)

        edit.addEventListener('click', (event) => {
            const key = event.target.getAttribute('data-key')
            const title = event.target.getAttribute('data-title')
            const volumeAttribute = event.target.getAttribute('data-volume')
            const valueAttribute = event.target.getAttribute('data-value')
            const stakeholderAttribute = event.target.getAttribute('data-stakeholder')
            const categoryAttribute = event.target.getAttribute('data-category')
            const inputToActivityAttribute = event.target.getAttribute('data-inputToActivity')
            // const inputNotesAttribute = event.target.getAttribute('data-inputNotes')
            const locationId = event.target.getAttribute('data-geocode')
            const lat = event.target.getAttribute('data-lat')
            const lng = event.target.getAttribute('data-lng')

            // const inputCategories = ['money', 'Equipment - Actual Value', 'Advice', 'borrow', 'Expertise', 'Volunteering'];
            const inputStakeholders = Object.values(project.stakeholders).map(stakeholder => stakeholder.stakeholderTitle);

            const stakeholderDropdownFunction = (categoryArr, categoryAttribute) => {
                let options = "<option value=''>None</option>";
                categoryArr.forEach((category) => {
                    if (`"${category}"`.toLowerCase() === `${categoryAttribute}`.toLowerCase()) {
                        options = options + `<option selected='selected' value='${category}'>${category[0].toUpperCase() + category.substring(1)}</option>`;
                    } else {
                        options = options + `<option value='${category}'>${category[0].toUpperCase() + category.substring(1)}</option>`;
                    }
                })
                return options;
            }
            let geoBox;
            if (document.getElementById('location')) {
                geoBox = `<div class='col-1-of-2'><label class='form-account__label' for='geographyUpdate'>Geography</label>
                    <input id='geographyUpdate' class='form-account__input' type='text' name='geographyUpdate'><div id="popup-location-container" class="outcome-location-list"></div></div><div class='col-1-of-2'><label class='form-account__label' for='inputToActivityUpdate'>Link Inputs to Activity</label>
                    <input id='inputToActivityUpdate' class='form-account__input' type='text' name='inputToActivityUpdate' value=${inputToActivityAttribute}></div>`
            } else {
                geoBox = `<label class='form-account__label' for='inputToActivityUpdate'>Link Inputs to Activity</label>
                <input id='inputToActivityUpdate' class='form-account__input' type='text' name='inputToActivityUpdate' value=${inputToActivityAttribute}></div>`
            }

            modal.style.display = 'block'
            // modal.style.visibility = 'visible'

            modal.innerHTML = `<div class='modal__content-small'>
                                        <div class='modal--create__left'>
                                            <h2 class='heading-account u-margin-bottom-small'>Update Input</h2>
                                            <form class='form form-update-input'>
                                                <div class='form-account__group'>
                                                    <input id='inputId' value=${key} type='hidden'>
                                                        <label class='form-account__label' for='titleUpdate'>Input Title</label>
                                                        <input id='titleUpdate' class='form-account__input' type='text' name='titleUpdate' value=${title}></div>
                                                        <div class='row'>
                                                            <div class='col-1-of-2'>
                                                                <div class='form-account__group ma-bt-sm'><label class='form-account__label' for='volumeUpdate'>Volume</label><input id='volumeUpdate' class='form-account__input' type='number' name='volumeUpdate' value=${volumeAttribute}></div>
                                                            </div>
                                                            <div class='col-1-of-2'>
                                                                <div class='form-account__group ma-bt-sm'><label class='form-account__label' for='valueUpdate'>Value (${inputCurrency})</label><input id='valueUpdate' class='form-account__input' type='number' step='0.01' name='valueUpdate' value=${valueAttribute}></div>
                                                            </div>
                                                        </div>
                                                        <div class='row'>
                                                            <div class='form-account__group ma-bt-sm'><label class='form-account__label' for='inputStakeholderUpdate'>Stakeholder</label>
                                                                <select id='inputStakeholderUpdate' class='form-account__input'>
                                                                    ${stakeholderDropdownFunction(inputStakeholders, stakeholderAttribute)}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class='form-account__group'>
                                                        <div class='row'>
                                                            ${geoBox}
                                                            </div>
                                                        <a class='modal__close' href=''>&times;</a>
                                                        <div class='form-account__group right'>
                                                            <button class='btn-account btn--small btn--green btn--update-input' type='submit'>Update Input</button>
                                                        </div>
                                                </div>
                                            </form>
                                        </div>`

            let updateInputForm = document.querySelector('.form-update-input')

            document.querySelector('.modal__close').addEventListener('click', (ev) => {
                ev.preventDefault()
                modal.style.display = 'none'
                // modal.style.visibility = 'hidden'
            })

            const geographyUpdateElement = document.getElementById('geographyUpdate');
            const geographyUpdateContainer = document.querySelector("#popup-location-container");

            if (geographyUpdateElement) {
                // if the locationId exists, fetch the correct geographical data from the api
                if (locationId && locationId !== 'undefined' && locationId !== null && locationId !== 'null') {
                    try {
                        fetchGeo(locationId)
                            .then((geoDescrip) => {
                                // Use geoDescrip here
                                if (geographyUpdateElement) {
                                    geographyUpdateElement.value = geoDescrip;
                                    geographyUpdateElement.setAttribute('data-location-id', locationId);
                                    geographyUpdateElement.setAttribute('data-lat', lat);
                                    geographyUpdateElement.setAttribute('data-lng', lng);
                                    // Attach event listener when the element is available
                                    geographyUpdateElement.addEventListener('input', () => {
                                        getLocation(geographyUpdateElement, geographyUpdateContainer, {
                                            dropdownClass: "input-actual-dropdown",
                                            listClass: "input-actual-dropdown-ul",
                                            itemClass: "input-actual-dropdown-item"
                                        });
                                    });
                                }
                            })
                            .catch((err) => {
                                console.log(err)
                            });
                    } catch (err) {
                        console.error(err);
                    }
                } else {
                    // If no data is available, leave the input box blank and attach event listener
                    geographyUpdateElement.addEventListener('input', () => {
                        const inputValue = geographyUpdateElement.value;
                        if (inputValue.trim() !== '') {
                            getLocation(geographyUpdateElement, geographyUpdateContainer, {
                                dropdownClass: "input-actual-dropdown",
                                listClass: "input-actual-dropdown-ul",
                                itemClass: "input-actual-dropdown-item"
                            });
                        }
                    });
                }
            }
            // If the update Input form is submitted, send to API
            if (updateInputForm) {
                updateInputForm.addEventListener('submit', async (e) => {
                    e.preventDefault()
                    const inputId = document.getElementById('inputId').value
                    const title = document.getElementById('titleUpdate').value
                    const volume = document.getElementById('volumeUpdate').value
                    // const inputNotes = document.getElementById('inputNotesUpdate').value
                    const stakeholder = document.getElementById('inputStakeholderUpdate').value
                    const inputToActivity = document.getElementById('inputToActivityUpdate').value
                    const value = document.getElementById('valueUpdate').value
                    const geographyUpdateElement = document.getElementById('geographyUpdate');
                    // 
                    let geocode = {};
                    if (geographyUpdateElement) {
                        geocode = {
                            locationId: geographyUpdateElement.getAttribute('data-location-id'),
                            lat: geographyUpdateElement.getAttribute('data-lat'),
                            lng: geographyUpdateElement.getAttribute('data-lng'),
                        };
                    }
                    await updateInput(inputId, { title, volume, inputToActivity, stakeholder, value, geocode });

                    document.querySelector('.btn--update-input').textContent = 'Updating...'
                    window.setTimeout(() => {
                        location.reload()
                    }, 1000)
                })
            }
        })
        // Add the targets to the myTargets element in the DOM
        myInputs.appendChild(button)
    })
}

if (outputsTwo) {
    const myOutputsTwo = document.getElementById('myOutputsTwo')
    const modal = document.getElementById('outputsTwoModal')
    project.outputtwos.forEach(el => {
        const button = document.createElement('div')

        const edit = document.createElement('button')
        const deleteOutputsTwoButton = document.createElement('button')
        const duplicateOutputsTwoButton = document.createElement('button')
        const spando = document.createElement('span')
        spando.className = 'review-span'
        edit.className = 'btn-account btn--small btn--green btn--update-target'
        duplicateOutputsTwoButton.className = 'btn-account btn--small btn--green btn--duplicate-target'
        deleteOutputsTwoButton.className = 'btn-account btn--small btn--red btn--delete-target'
        edit.innerHTML = '<svg class="review__icon"><use xlink:href="/img/icons.svg#icon-edit"></use></svg>'
        deleteOutputsTwoButton.innerHTML = '<svg class="review__icon"><use xlink:href="/img/icons.svg#icon-delete"></use></svg>'
        duplicateOutputsTwoButton.innerHTML = '<svg class="review__icon"><use xlink:href="/img/icons.svg#icon-copy"></use></svg>'

        const outputsTwoTrim = trimLength(el.title)
        button.className = 'reviews__card'
        button.innerHTML = `<div class='reviews__avatar'><h6 class='reviews__user'>${outputsTwoTrim}</h6></div><p class='reviews__text'></p><div class='reviews__rating'>${el.description}</div>`

        // Add the buttons to the box
        button.appendChild(spando)
        spando.appendChild(edit)
        spando.appendChild(duplicateOutputsTwoButton)
        spando.appendChild(deleteOutputsTwoButton)

        setAttributes(edit, {
            'data-key': el._id,
            'data-title': JSON.stringify(el.title),
            'data-description': JSON.stringify(el.description),
            'data-volume': el.volume,
            'data-stakeholder': JSON.stringify(el.stakeholder),
            'data-outputToActivity': JSON.stringify(el.outputToActivity)
        })

        setAttributes(deleteOutputsTwoButton, {
            'data-key': el._id
        })

        setAttributes(duplicateOutputsTwoButton, {
            'data-project': el.project,
            'data-title': el.title,
            'data-description': el.description,
            'data-volume': el.volume,
            'data-stakeholder': el.stakeholder,
            'data-outputToActivity': el.outputToActivity
        })

        // delete input button
        deleteOutputsTwoButton.addEventListener('click', deleteOutputTwoComponent)

        // duplicate input button
        duplicateOutputsTwoButton.addEventListener('click', duplicateOutputTwoComponent)

        edit.addEventListener('click', (event) => {
            const key = event.target.getAttribute('data-key')
            const title = event.target.getAttribute('data-title')
            const descriptionAttribute = event.target.getAttribute('data-description')
            const volumeAttribute = event.target.getAttribute('data-volume')
            const stakeholderAttribute = event.target.getAttribute('data-stakeholder')
            const outputToActivityAttribute = event.target.getAttribute('data-outputToActivity')

            modal.style.display = 'block'
            // modal.style.visibility = 'visible'

            modal.innerHTML = `<div class='modal__content-small'>
                                            <div class='modal--create__left'>
                                                <h2 class='heading-account u-margin-bottom-small'>Update Output</h2>
                                                <form class='form form-update-outputTwo'>
                                                    <div class='form-account__group'>
                                                        <input id='outputTwoId' value=${key} type='hidden'>
                                                            <label class='form-account__label' for='titleUpdate'>Output Title</label>
                                                            <input id='titleUpdate' class='form-account__input' type='text' name='titleUpdate' value=${title}></div>
                                                            <div class='row'>
                                                                <div class='col-1-of-2'>
                                                                    <div class='form-account__group ma-bt-sm'><label class='form-account__label' for='volumeUpdate'>Volume</label><input id='volumeUpdate' class='form-account__input' type='number' name='volumeUpdate' value=${volumeAttribute}></div>
                                                                </div>
                                                                <div class='col-1-of-2'>
                                                                    <div class='form-account__group ma-bt-sm'><label class='form-account__label' for='descriptionUpdate'>Output Description</label>
                                                                        <input id='descriptionUpdate' class='form-account__input' type='text' name='desciptionUpdate' value=${descriptionAttribute}></div>
                                                                </div>
                                                            </div>
                                                            <div class='form-account__group'>
                                                                <label class='form-account__label' for='outputToActivityUpdate'>Output To Activity</label>
                                                                <input id='outputToActivityUpdate' class='form-account__input' type='text' name='outputToActivityUpdate' value=${outputToActivityAttribute}></div>

                                                            <a class='modal__close' href=''>&times;</a>
                                                            <div class='form-account__group right'>
                                                                <button class='btn-account btn--small btn--green btn--update-outputTwo' type='submit'>Update Output</button>
                                                            </div>
                                                    </div>
                                                </form>
                                            </div>`

            let updateOutputTwoForm = document.querySelector('.form-update-outputTwo')

            document.querySelector('.modal__close').addEventListener('click', (ev) => {
                ev.preventDefault()
                modal.style.display = 'none'
                // modal.style.visibility = 'hidden'
            })

            // If the update Output form is submitted, send to API
            if (updateOutputTwoForm) {
                updateOutputTwoForm.addEventListener('submit', async (e) => {
                    e.preventDefault()
                    const outputTwoId = document.getElementById('outputTwoId').value
                    const title = document.getElementById('titleUpdate').value
                    const description = document.getElementById('descriptionUpdate').value
                    const volume = document.getElementById('volumeUpdate').value
                    const outputToActivity = document.getElementById('outputToActivityUpdate').value

                    await updateOutputTwo(outputTwoId, { title, volume, outputToActivity, description })

                    document.querySelector('.btn--update-outputTwo').textContent = 'Updating...'
                    window.setTimeout(() => {
                        location.reload()
                    }, 1000)
                })
            }
        })
        // Add the targets to the myTargets element in the DOM
        myOutputsTwo.appendChild(button)
    })
}

if (kpis) {
    const myKpi = document.getElementById('myKpi')
    const modal = document.getElementById('kpiModal')
    project.kpis.forEach(el => {
        const button = document.createElement('div')

        const edit = document.createElement('button')
        const deleteKpiButton = document.createElement('button')
        // const duplicateKpiButton = document.createElement('button')
        const spando = document.createElement('span')
        spando.className = 'review-span'
        edit.className = 'btn-account btn--small btn--green btn--update-kpi'
        // duplicateKpiButton.className = 'btn-account btn--small btn--green btn--duplicate-kpi'
        deleteKpiButton.className = 'btn-account btn--small btn--red btn--delete-kpi'
        edit.innerHTML = '<svg class="review__icon"><use xlink:href="/img/icons.svg#icon-edit"></use></svg>'
        deleteKpiButton.innerHTML = '<svg class="review__icon"><use xlink:href="/img/icons.svg#icon-delete"></use></svg>'
        // duplicateKpiButton.innerHTML = '<svg class="review__icon"><use xlink:href="/img/icons.svg#icon-copy"></use></svg>'

        const kpiTrim = trimLength(el.kpiTitle)
        button.className = 'reviews__card'
        button.innerHTML = `<div class='reviews__avatar'><h6 class='reviews__user'>${kpiTrim}</h6></div><p class='reviews__text'></p><div class='reviews__rating'>${el.kpiForecast}</div>`

        // Add the buttons to the box
        button.appendChild(spando)
        spando.appendChild(edit)
        // spando.appendChild(duplicateKpiButton)
        spando.appendChild(deleteKpiButton)

        setAttributes(edit, {
            'data-key': el._id,
            'data-title': JSON.stringify(el.kpiTitle),
            'data-parent-id': JSON.stringify(el.kpiId),
            'data-forecast': el.kpiForecast,
            'data-project': el.project
        })

        setAttributes(deleteKpiButton, {
            'data-key': el._id
        })

        // delete input button
        deleteKpiButton.addEventListener('click', deleteKpiComponent)

        // duplicate input button
        // duplicateKpiButton.addEventListener('click', duplicateKpiComponent)

        edit.addEventListener('click', (event) => {
            const key = event.target.getAttribute('data-key')
            const kpiTitle = event.target.getAttribute('data-title')
            const kpiId = event.target.getAttribute('data-parent-id')
            const project = event.target.getAttribute('data-project')
            const kpiForecast = event.target.getAttribute('data-forecast')

            modal.style.display = 'block'
            // modal.style.visibility = 'visible'
            const sanitisedTitle = kpiTitle.replace(/"/g, ""); // Remove all quotation marks from the title

            modal.innerHTML = `<div class='modal__content-small'>
                                                <div class='modal--create__left'>
                                                    <h2 class='heading-account u-margin-bottom-small'>${sanitisedTitle}</h2>
                                                    <form class='form form-update-kpi'>
                                                        <div class='form-account__group'>
                                                            <input id='kpiId' value=${key} type='hidden'>

                                                                <div class='form-account__group'>
                                                                    <label class='form-account__label' for='forecastUpdate'>Total to Measure Against</label>
                                                                    <input id='forecastUpdate' class='form-account__input' type='number' step="0.1" name='forecastUpdate' value=${kpiForecast}></div>
                                                                <a class='modal__close' href=''>&times;</a>
                                                                <div class='form-account__group right'>
                                                                    <button class='btn-account btn--small btn--green btn--update-kpi' type='submit'>Update KPI</button>
                                                                </div>
                                                        </div>
                                                    </form>
                                                </div>`

            let updateKpiForm = document.querySelector('.form-update-kpi')

            document.querySelector('.modal__close').addEventListener('click', (ev) => {
                ev.preventDefault()
                modal.style.display = 'none'
                // modal.style.visibility = 'hidden'
            })

            // If the update Output form is submitted, send to API
            if (updateKpiForm) {
                updateKpiForm.addEventListener('submit', async (e) => {
                    e.preventDefault()
                    const kpiId = document.getElementById('kpiId').value
                    const kpiForecast = document.getElementById('forecastUpdate').value

                    await updateKpi(kpiId, { kpiForecast });

                    document.querySelector('.btn--update-kpi').textContent = 'Updating...'
                    window.setTimeout(() => {
                        location.reload()
                    }, 1000)
                })
            }
        })
        // Add the targets to the myTargets element in the DOM
        myKpi.appendChild(button)
    })
}

if (stakeholders) {
    const myStakeholders = document.getElementById('myStakeholders')
    const modal = document.getElementById('stakeholderModal')

    project.stakeholders.forEach(el => {
        // console.log(el);
        const button = document.createElement('div')
        const edit = document.createElement('button')
        const deleteStakeholderButton = document.createElement('button')
        const duplicateStakeholderButton = document.createElement('button')
        const spando = document.createElement('span')
        spando.className = 'review-span'
        edit.className = 'btn-account btn--small btn--green btn--update-target'
        duplicateStakeholderButton.className = 'btn-account btn--small btn--green btn--duplicate-target'
        deleteStakeholderButton.className = 'btn-account btn--small btn--red btn--delete-target'
        edit.innerHTML = '<svg class="review__icon"><use xlink:href="/img/icons.svg#icon-edit"></use></svg>'
        deleteStakeholderButton.innerHTML = '<svg class="review__icon"><use xlink:href="/img/icons.svg#icon-delete"></use></svg>'
        duplicateStakeholderButton.innerHTML = '<svg class="review__icon"><use xlink:href="/img/icons.svg#icon-copy"></use></svg>'

        const stakeholderTrim = trimLength(el.stakeholderTitle)
        button.className = 'reviews__card'
        button.innerHTML = `<div class='reviews__avatar'><h6 class='reviews__user'>${stakeholderTrim}</h6>
                                                </div><p class='reviews__text'></p><div class='reviews__rating'></div>`

        // Add the buttons to the box
        button.appendChild(spando)
        spando.appendChild(edit)
        spando.appendChild(duplicateStakeholderButton)
        spando.appendChild(deleteStakeholderButton)

        // console.log(el.subgroup)


        if (!Array.isArray(el.subgroup) || !el.subgroup.length) {
            // array does not exist, is not an array, or is empty
            // ⇒ do not attempt to process array
            setAttributes(edit, {
                'data-key': el._id,
                'data-stakeholderTitle': JSON.stringify(el.stakeholderTitle),
                // 'data-evidenceOfOutcome': JSON.stringify(el.evidenceOfOutcome),
                'data-evidenceOfOutcome': el.evidenceOfOutcome,
                'data-reasonForInclusion': JSON.stringify(el.reasonForInclusion),
                'data-positiveNegative': JSON.stringify(el.positiveNegative),
                'data-method': JSON.stringify(el.method),
                'data-when': JSON.stringify(el.when)
            })
            setAttributes(duplicateStakeholderButton, {
                'data-key': el._id,
                'data-stakeholderTitle': el.stakeholderTitle,
                'data-evidenceOfOutcome': el.evidenceOfOutcome,
                // 'data-reasonForInclusion': el.reasonForInclusion,
                'data-stakeholderInclusion': el.stakeholderInclusion,
                'data-stakeholderInvolvement': el.stakeholderInvolvement,
                'data-positiveNegative': el.positiveNegative,
                'data-method': el.method,
                'data-when': el.when,
                'data-population': el.population,
                'data-project': project._id
            })

        } else {
            setAttributes(edit, {
                'data-key': el._id,
                'data-stakeholderTitle': JSON.stringify(el.stakeholderTitle),
                // 'data-evidenceOfOutcome': JSON.stringify(el.evidenceOfOutcome),
                'data-evidenceOfOutcome': el.evidenceOfOutcome,
                'data-reasonForInclusion': JSON.stringify(el.reasonForInclusion),
                'data-positiveNegative': JSON.stringify(el.positiveNegative),
                'data-method': JSON.stringify(el.method),
                'data-when': JSON.stringify(el.when),
                'data-population': JSON.stringify(el.population),
                'data-subgroup-1': JSON.stringify(el.subgroup[0].subgroupTitle),
                'data-subgroup-1-percent': el.subgroup[0].subgroupPercent,
                'data-subgroup-2': JSON.stringify(el.subgroup[1].subgroupTitle),
                'data-subgroup-2-percent': el.subgroup[1].subgroupPercent,
                'data-subgroup-3': JSON.stringify(el.subgroup[2].subgroupTitle),
                'data-subgroup-3-percent': el.subgroup[2].subgroupPercent,
                'data-subgroup-4': JSON.stringify(el.subgroup[3].subgroupTitle),
                'data-subgroup-4-percent': el.subgroup[3].subgroupPercent
            })
            setAttributes(duplicateStakeholderButton, {
                'data-key': el._id,
                'data-stakeholderTitle': el.stakeholderTitle,
                'data-evidenceOfOutcome': el.evidenceOfOutcome,
                // 'data-reasonForInclusion': el.reasonForInclusion,
                'data-stakeholderInclusion': el.stakeholderInclusion,
                'data-stakeholderInvolvement': el.stakeholderInvolvement,
                'data-positiveNegative': el.positiveNegative,
                'data-method': el.method,
                'data-when': el.when,
                'data-population': el.population,
                'data-project': project._id,
                'data-subgroup-1': JSON.stringify(el.subgroup[0].subgroupTitle),
                'data-subgroup-1-percent': el.subgroup[0].subgroupPercent,
                'data-subgroup-2': JSON.stringify(el.subgroup[1].subgroupTitle),
                'data-subgroup-2-percent': el.subgroup[1].subgroupPercent,
                'data-subgroup-3': JSON.stringify(el.subgroup[2].subgroupTitle),
                'data-subgroup-3-percent': el.subgroup[2].subgroupPercent,
                'data-subgroup-4': JSON.stringify(el.subgroup[3].subgroupTitle),
                'data-subgroup-4-percent': el.subgroup[3].subgroupPercent
            })
        }

        setAttributes(deleteStakeholderButton, {
            'data-key': el._id,
            'data-project': project._id
        })

        // delete stakeholder button
        deleteStakeholderButton.addEventListener('click', deleteStakeholderComponent)

        // // duplicate target button
        duplicateStakeholderButton.addEventListener('click', duplicateStakeholderComponent)

        edit.addEventListener('click', (event) => {
            const key = event.target.getAttribute('data-key')
            const updateTitle = event.target.getAttribute('data-stakeholderTitle')
            const stakeholderEvidenceOfOutcome = event.target.getAttribute('data-evidenceOfOutcome')
            const stakeholderReasonForInclusion = event.target.getAttribute('data-reasonForInclusion')
            const stakeholderPositiveNegative = event.target.getAttribute('data-positiveNegative')
            const stakeholderMethod = event.target.getAttribute('data-method')
            const stakeholderWhen = event.target.getAttribute('data-when')
            let isSubgroup = false
            let subgroupOneTitle
            let subgroupOnePercent
            let subgroupTwoTitle
            let subgroupTwoPercent
            let subgroupThreeTitle
            let subgroupThreePercent
            let subgroupFourTitle
            let subgroupFourPercent

            // document.querySelector('.body').style.fontSize = '50%'

            // if there is a subgroup
            if (event.target.getAttribute('data-subgroup-1')) {
                subgroupOneTitle = event.target.getAttribute('data-subgroup-1')
                subgroupOnePercent = event.target.getAttribute('data-subgroup-1-percent')
                subgroupTwoTitle = event.target.getAttribute('data-subgroup-2')
                subgroupTwoPercent = event.target.getAttribute('data-subgroup-2-percent')
                subgroupThreeTitle = event.target.getAttribute('data-subgroup-3')
                subgroupThreePercent = event.target.getAttribute('data-subgroup-3-percent')
                subgroupFourTitle = event.target.getAttribute('data-subgroup-4')
                subgroupFourPercent = event.target.getAttribute('data-subgroup-4-percent')
                isSubgroup = true
                const modalContent = document.querySelector('.modal__content-stakeholder')


                modal.style.display = 'block'
                // document.querySelector('html').style.fontSize = '56.25%'
                // modal.style.fontSize = '50%'

                ///// THIS BELOW IS THE ORIGINAL STAKEHOLDER POPUP WITH THE STAKEHOLDER GUBBINS ABOUT IF, POS OR NEG ETC ////////////

                // modal.innerHTML = `<div class='modal__content-stakeholder'>
                //                 <div class='modal--create__left'>
                //                     <h2 class='heading-account u-margin-bottom-small'>Update Stakeholder</h2>
                //                             <form class='form form-update-stakeholder'>
                //                             <div class='form-account__group'>
                //                                 <input id='stakeholderId' value=${key} type='hidden'>
                //                                 <input id='projectId' value=${project._id} type='hidden'>
                //                                 <label class='form-account__label' for='updateTitle'>Stakeholder</label>
                //                                 <input id='updateTitle' class='form-account__input' type='text' name='updateTitle' value=${updateTitle}></div>
                //                                 <div class='form-account__group ma-bt-sm'>
                //                                 <div class='row'>
                //                                 <div class='col-1-of-2'>
                //                                 <label class='form-account__label' for='stakeholderReasonForInclusion'>Reason For Inclusion</label><input id='stakeholderReasonForInclusion' class='form-account__input' type='text' name='stakeholderReasonForInclusion' value=${stakeholderReasonForInclusion}></div>
                //                                 <div class='col-1-of-2'>
                //                                 <label class='form-account__label' for='stakeholderPositiveNegative'>Positive Negative</label><input id='stakeholderPositiveNegative' class='form-account__input' type='text' name='stakeholderPositiveNegative' value=${stakeholderPositiveNegative}></div>
                //                                 </div>
                //                                 </div>
                //                                 <div class='row'>
                //                                 <div class='form-account__group ma-bt-sm'>
                //                                 <div class='row'>
                //                                 <div class='col-1-of-2'>
                //                                 <label class='form-account__label' for='stakeholderMethod'>Method</label><input id='stakeholderMethod' class='form-account__input' type='text' name='stakeholderMethod' value=${stakeholderMethod}></div>
                //                                 <div class='col-1-of-2'>
                //                                 <div class='form-account__group ma-bt-sm'><label class='form-account__label' for='stakeholderWhen'>When</label><input id='stakeholderWhen' class='form-account__input' type='text' name='stakeholderWhen' value=${stakeholderWhen}></div>
                //                                 </div>
                //                                 </div>
                //                                 <div class='row-subgroup'>
                //                                 <div class='col-1-of-2'>
                //                                 <label class='form-account__label-subgroup' for='subgroupEdit'>Are there sub-groups?</label>
                //                                 </div>
                //                                 <div class='col-1-of-2'>
                //                                 <div class='col-1-of-2'>
                //                                 <input id='editpositive' class='form__radio-input' type='radio' name='subgroupEdit' value='editpositive' checked=checked />
                //                                 <label class='form__radio-label' for='editpositive'>
                //                                     <span class='form__radio-button'></span>Yes</label>
                //                                 </div>
                //                                 <div class='col-1-of-2'>
                //                                 <input id='editnegative' class='form__radio-input' type='radio' name='subgroupEdit' value='editnegative' />
                //                                 <label class='form__radio-label' for='editnegative'>
                //                                     <span class='form__radio-button'></span>No</label>
                //                                 </div>
                //                                 </div>
                //                                 </div>
                //                                 <div class='row'>
                //                                 <div class='subgroupEdit-accordion-show'>
                //                                 <div class='row-subgroup'>
                //                                 <div class='col-1-of-2'>
                //                                 <div class='col-2-of-3'>
                //                                 <label class='form-account__label' for='subGroupOneEdit'>Subgroup 1</label><input id='subGroupOneEdit' class='form-account__input-subgroup' type='text' name='subGroupOneEdit' value=${subgroupOneTitle}></div>
                //                                 <div class='col-1-of-3'>
                //                                 <label class='form-account__label' for='subGroupOneEditPercent'>%</label>
                //                                 <input id='subGroupOneEditPercent' class='form-account__input-subgroupPercent' type='number' name='subGroupOneEditPercent' value=${subgroupOnePercent}></div>
                //                                 </div>
                //                                 <div class='col-1-of-2'>
                //                                 <div class='col-2-of-3'>
                //                                 <label class='form-account__label' for='subGroupThreeEdit'>Subgroup 3</label><input id='subGroupThreeEdit' class='form-account__input-subgroup' type='text' name='subGroupThreeEdit' value=${subgroupThreeTitle}></div>
                //                                 <div class='col-1-of-3'>
                //                                 <label class='form-account__label' for='subGroupThreeEditPercent'>%</label>
                //                                 <input id='subGroupThreeEditPercent' class='form-account__input-subgroupPercent' type='number' name='subGroupThreeEditPercent' value=${subgroupThreePercent}></div>
                //                                 </div>
                //                                 </div>
                //                                 <div class='row'>
                //                                 <div class='col-1-of-2'>
                //                                 <div class='col-2-of-3'>
                //                                 <label class='form-account__label' for='subGroupTwoEdit'>Subgroup 2</label><input id='subGroupTwoEdit' class='form-account__input-subgroup' type='text' name='subGroupTwoEdit' value=${subgroupTwoTitle}></div>
                //                                 <div class='col-1-of-3'>
                //                                 <label class='form-account__label' for='subGroupTwoEditPercent'>%</label>
                //                                 <input id='subGroupTwoEditPercent' class='form-account__input-subgroupPercent' type='number' name='subGroupTwoEditPercent' value=${subgroupTwoPercent}></div>
                //                                 </div>
                //                                 <div class='col-1-of-2'>
                //                                 <div class='col-2-of-3'>
                //                                 <label class='form-account__label' for='subGroupFourEdit'>Subgroup 4</label><input id='subGroupFourEdit' class='form-account__input-subgroup' type='text' name='subGroupFourEdit' value=${subgroupFourTitle}></div>
                //                                 <div class='col-1-of-3'>
                //                                 <label class='form-account__label' for='subGroupFourEditPercent'>%</label>
                //                                 <input id='subGroupFourEditPercent' class='form-account__input-subgroupPercent' type='number' name='subGroupFourEditPercent' value=${subgroupFourPercent}></div>
                //                                 </div>
                //                                 </div>
                //                                 </div>
                //                                 <a class='modal__close' href=''>&times;</a>
                //                                 <div class='form-account__group right' style='margin-top: 1rem'>
                //                                 <button class='btn-account btn--small btn--green btn--update-stakeholder' type='submit'>Update Stakeholder</button>
                //                                 </div>
                //                                 </div>

                //                             </form>
                //                         </div>`

                ///////////// END OF STAKEHOLDER POPUP GUBBINS, BELOW IS COPY WITH IF, POS NEG ETC REMOVED, IF THERE IS A SUBGROUP ///////////////////////////////////////

                modal.innerHTML = `<div class='modal__content-stakeholder'>
                                                                            <div class='modal--create__left'>
                                                                                <h2 class='heading-account u-margin-bottom-small'>Update Stakeholder</h2>
                                                                                <form class='form form-update-stakeholder'>
                                                                                    <div class='form-account__group'>
                                                                                        <input id='stakeholderId' value=${key} type='hidden'>
                                                                                            <input id='projectId' value=${project._id} type='hidden'>
                                                                                                <label class='form-account__label' for='updateTitle'>Stakeholder</label>
                                                                                                <input id='updateTitle' class='form-account__input' type='text' name='updateTitle' value=${updateTitle}></div>
                                                                                                <div class='form-account__group ma-bt-sm'>
                                                                                                    <div class='row'>
                                                                                                        <label class='form-account__label' for='stakeholderEvidenceOfOutcome'>Stakeholder Evidence of Outcome (optional)</label><textarea id='stakeholderEvidenceOfOutcome' class='form-account__textarea' type='text' name='stakeholderEvidenceOfOutcome'>${stakeholderEvidenceOfOutcome}</textarea>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class='row'>

                                                                                                    <div class='row-subgroup'>
                                                                                                        <div class='col-1-of-2'>
                                                                                                            <label class='form-account__label-subgroup' for='subgroupEdit'>Are there sub-groups?</label>
                                                                                                        </div>
                                                                                                        <div class='col-1-of-2'>
                                                                                                            <div class='col-1-of-2'>
                                                                                                                <input id='editpositive' class='form__radio-input' type='radio' name='subgroupEdit' value='editpositive' checked=checked />
                                                                                                                <label class='form__radio-label' for='editpositive'>
                                                                                                                    <span class='form__radio-button'></span>Yes</label>
                                                                                                            </div>
                                                                                                            <div class='col-1-of-2'>
                                                                                                                <input id='editnegative' class='form__radio-input' type='radio' name='subgroupEdit' value='editnegative' />
                                                                                                                <label class='form__radio-label' for='editnegative'>
                                                                                                                    <span class='form__radio-button'></span>No</label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class='row'>
                                                                                                        <div class='subgroupEdit-accordion-show'>
                                                                                                            <div class='row-subgroup'>
                                                                                                                <div class='col-1-of-2'>
                                                                                                                    <div class='col-2-of-3'>
                                                                                                                        <label class='form-account__label' for='subGroupOneEdit'>Subgroup 1</label><input id='subGroupOneEdit' class='form-account__input-subgroup' type='text' name='subGroupOneEdit' value=${subgroupOneTitle}></div>
                                                                                                                    <div class='col-1-of-3'>
                                                                                                                        <label class='form-account__label' for='subGroupOneEditPercent'>Number</label>
                                                                                                                        <input id='subGroupOneEditPercent' class='form-account__input-subgroupPercent' type='number' name='subGroupOneEditPercent' value=${subgroupOnePercent}></div>
                                                                                                                </div>
                                                                                                                <div class='col-1-of-2'>
                                                                                                                    <div class='col-2-of-3'>
                                                                                                                        <label class='form-account__label' for='subGroupThreeEdit'>Subgroup 3</label><input id='subGroupThreeEdit' class='form-account__input-subgroup' type='text' name='subGroupThreeEdit' value=${subgroupThreeTitle}></div>
                                                                                                                    <div class='col-1-of-3'>
                                                                                                                        <label class='form-account__label' for='subGroupThreeEditPercent'>Number</label>
                                                                                                                        <input id='subGroupThreeEditPercent' class='form-account__input-subgroupPercent' type='number' name='subGroupThreeEditPercent' value=${subgroupThreePercent}></div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div class='row'>
                                                                                                                <div class='col-1-of-2'>
                                                                                                                    <div class='col-2-of-3'>
                                                                                                                        <label class='form-account__label' for='subGroupTwoEdit'>Subgroup 2</label><input id='subGroupTwoEdit' class='form-account__input-subgroup' type='text' name='subGroupTwoEdit' value=${subgroupTwoTitle}></div>
                                                                                                                    <div class='col-1-of-3'>
                                                                                                                        <label class='form-account__label' for='subGroupTwoEditPercent'>Number</label>
                                                                                                                        <input id='subGroupTwoEditPercent' class='form-account__input-subgroupPercent' type='number' name='subGroupTwoEditPercent' value=${subgroupTwoPercent}></div>
                                                                                                                </div>
                                                                                                                <div class='col-1-of-2'>
                                                                                                                    <div class='col-2-of-3'>
                                                                                                                        <label class='form-account__label' for='subGroupFourEdit'>Subgroup 4</label><input id='subGroupFourEdit' class='form-account__input-subgroup' type='text' name='subGroupFourEdit' value=${subgroupFourTitle}></div>
                                                                                                                    <div class='col-1-of-3'>
                                                                                                                        <label class='form-account__label' for='subGroupFourEditPercent'>Number</label>
                                                                                                                        <input id='subGroupFourEditPercent' class='form-account__input-subgroupPercent' type='number' name='subGroupFourEditPercent' value=${subgroupFourPercent}></div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <a class='modal__close' href=''>&times;</a>
                                                                                                        <div class='form-account__group right' style='margin-top: 1rem'>
                                                                                                            <button class='btn-account btn--small btn--green btn--update-stakeholder' type='submit'>Update Stakeholder</button>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                </form>
                                                                                            </div>`

                const slideDown = element => element.style.height = `${element.scrollHeight}px`
                const slideUp = (element) => element.style.height = `0px`
                // const slideUp = element => element.style.display = `none`
                // if subgroup radio button is yes, show subgroup input boxes - otherwise, hide and clear
                // For some reason, yes>no makes the div 'jump' back rather than slide, so need to add this to make it happen correctly
                if (document.getElementById('editpositive').checked) {
                    slideDown(document.querySelector('.subgroupEdit-accordion-show'));
                } else {
                    slideUp(document.querySelector('.subgroupEdit-accordion-show'));
                }

                document.getElementById('editpositive').addEventListener('click', async (e) => {
                    slideDown(document.querySelector('.subgroupEdit-accordion-show'))
                })
                document.getElementById('editnegative').addEventListener('click', async (e) => {
                    slideUp(document.querySelector('.subgroupEdit-accordion-show'))

                    // clear values from the form when no sub-groups
                    document.getElementById('subGroupOneEdit').value = ''
                    document.getElementById('subGroupOneEditPercent').value = ''
                    document.getElementById('subGroupTwoEdit').value = ''
                    document.getElementById('subGroupTwoEditPercent').value = ''
                    document.getElementById('subGroupThreeEdit').value = ''
                    document.getElementById('subGroupThreeEditPercent').value = ''
                    document.getElementById('subGroupFourEdit').value = ''
                    document.getElementById('subGroupFourEditPercent').value = ''
                })

            } else {
                // if no subgroups from the original
                modal.style.display = 'block'

                /////////// BELOW IS THE ORIGINAL POPUP MODAL IF THERE WERE NO SUBGROUPS BUT WITH THE IF, POS/NEG ETC - THIS SHOULD WORK IF IT"S ALL TURNED BACK ON (INCLUDING THE VALUES PASSED INTO THE FUNCTIONS) ////////////

                // modal.innerHTML = `<div class='modal__content-stakeholder'>
                //     <div class='modal--create__left'>
                //         <h2 class='heading-account u-margin-bottom-small'>Update Stakeholder</h2>
                //         <form class='form form-update-stakeholder'>
                //             <div class='form-account__group'>
                //                 <input id='stakeholderId' value=${key} type='hidden'>
                //                     <input id='projectId' value=${project._id} type='hidden'>
                //                         <label class='form-account__label' for='updateTitle'>Stakeholder</label>
                //                         <input id='updateTitle' class='form-account__input' type='text' name='updateTitle' value=${updateTitle}></div>
                //                         <div class='form-account__group ma-bt-sm'>
                //                             <div class='row'>
                //                                 <div class='col-1-of-2'>
                //                                     <label class='form-account__label' for='stakeholderReasonForInclusion'>Reason For Inclusion</label><input id='stakeholderReasonForInclusion' class='form-account__input' type='text' name='stakeholderReasonForInclusion' value=${stakeholderReasonForInclusion}></div>
                //                                     <div class='col-1-of-2'>
                //                                         <label class='form-account__label' for='stakeholderPositiveNegative'>Positive Negative</label><input id='stakeholderPositiveNegative' class='form-account__input' type='text' name='stakeholderPositiveNegative' value=${stakeholderPositiveNegative}></div>
                //                                     </div>
                //                                 </div>
                //                                 <div class='row'>
                //                                     <div class='form-account__group ma-bt-sm'>
                //                                         <div class='row'>
                //                                             <div class='col-1-of-2'>
                //                                                 <label class='form-account__label' for='stakeholderMethod'>Method</label><input id='stakeholderMethod' class='form-account__input' type='text' name='stakeholderMethod' value=${stakeholderMethod}></div>
                //                                                 <div class='col-1-of-2'>
                //                                                     <div class='form-account__group ma-bt-sm'><label class='form-account__label' for='stakeholderWhen'>When</label><input id='stakeholderWhen' class='form-account__input' type='text' name='stakeholderWhen' value=${stakeholderWhen}></div>
                //                                                     </div>
                //                                                 </div>
                //                                                 <div class='row-subgroup'>
                //                                                     <div class='col-1-of-2'>
                //                                                         <label class='form-account__label-subgroup' for='subgroupEdit'>Are there sub-groups?</label>
                //                                                     </div>
                //                                                     <div class='col-1-of-2'>
                //                                                         <div class='col-1-of-2'>
                //                                                             <input id='editpositive' class='form__radio-input' type='radio' name='subgroupEdit' value='editpositive' />
                //                                                             <label class='form__radio-label' for='editpositive'>
                //                                                                 <span class='form__radio-button'></span>Yes</label>
                //                                                         </div>
                //                                                         <div class='col-1-of-2'>
                //                                                             <input id='editnegative' class='form__radio-input' type='radio' name='subgroupEdit' value='editnegative' checked=checked />
                //                                                             <label class='form__radio-label' for='editnegative'>
                //                                                                 <span class='form__radio-button'></span>No</label>
                //                                                         </div>
                //                                                     </div>
                //                                                 </div>
                //                                                 <div class='row'>
                //                                                     <div class='subgroupEdit-accordion'>
                //                                                         <div class='row-subgroup'>
                //                                                             <div class='col-1-of-2'>
                //                                                                 <div class='col-2-of-3'>
                //                                                                     <label class='form-account__label' for='subGroupOneEdit'>Subgroup 1</label><input id='subGroupOneEdit' class='form-account__input-subgroup' type='text' name='subGroupOneEdit'></div>
                //                                                                     <div class='col-1-of-3'>
                //                                                                         <label class='form-account__label' for='subGroupOneEditPercent'>%</label>
                //                                                                         <input id='subGroupOneEditPercent' class='form-account__input-subgroupPercent' type='number' name='subGroupOneEditPercent'></div>
                //                                                                     </div>
                //                                                                     <div class='col-1-of-2'>
                //                                                                         <div class='col-2-of-3'>
                //                                                                             <label class='form-account__label' for='subGroupThreeEdit'>Subgroup 3</label><input id='subGroupThreeEdit' class='form-account__input-subgroup' type='text' name='subGroupThreeEdit'></div>
                //                                                                             <div class='col-1-of-3'>
                //                                                                                 <label class='form-account__label' for='subGroupThreeEditPercent'>%</label>
                //                                                                                 <input id='subGroupThreeEditPercent' class='form-account__input-subgroupPercent' type='number' name='subGroupThreeEditPercent'></div>
                //                                                                             </div>
                //                                                                         </div>
                //                                                                         <div class='row'>
                //                                                                             <div class='col-1-of-2'>
                //                                                                                 <div class='col-2-of-3'>
                //                                                                                     <label class='form-account__label' for='subGroupTwoEdit'>Subgroup 2</label><input id='subGroupTwoEdit' class='form-account__input-subgroup' type='text' name='subGroupTwoEdit'></div>
                //                                                                                     <div class='col-1-of-3'>
                //                                                                                         <label class='form-account__label' for='subGroupTwoEditPercent'>%</label>
                //                                                                                         <input id='subGroupTwoEditPercent' class='form-account__input-subgroupPercent' type='number' name='subGroupTwoEditPercent'></div>
                //                                                                                     </div>
                //                                                                                     <div class='col-1-of-2'>
                //                                                                                         <div class='col-2-of-3'>
                //                                                                                             <label class='form-account__label' for='subGroupFourEdit'>Subgroup 4</label><input id='subGroupFourEdit' class='form-account__input-subgroup' type='text' name='subGroupFourEdit'></div>
                //                                                                                             <div class='col-1-of-3'>
                //                                                                                                 <label class='form-account__label' for='subGroupFourEditPercent'>%</label>
                //                                                                                                 <input id='subGroupFourEditPercent' class='form-account__input-subgroupPercent' type='number' name='subGroupFourEditPercent'></div>
                //                                                                                             </div>
                //                                                                                         </div>
                //                                                                                     </div>
                //                                                                                     <a class='modal__close' href=''>&times;</a>
                //                                                                                         <div class='form-account__group right' style='margin-top: 1rem'>
                //                                                                                             <button class='btn-account btn--small btn--green btn--update-stakeholder' type='submit'>Update Stakeholder</button>
                //                                                                                         </div>
                //                                                                                     </div>
                //                                                                                 </div>
                //                             </form>
                //                                                                         </div>`
                //////////// END OF ORIG //////////////////////////////

                ////////// REPLACEMENT POPUP IF THERE IS NO SUBGROUP WITHOUT THE IF, WHEN, POS/NEG ETC ////////////
                modal.innerHTML = `<div class='modal__content-stakeholder'>
                                                                                                                    <div class='modal--create__left'>
                                                                                                                        <h2 class='heading-account u-margin-bottom-small'>Update Stakeholder</h2>
                                                                                                                        <form class='form form-update-stakeholder'>
                                                                                                                            <div class='form-account__group'>
                                                                                                                                <input id='stakeholderId' value=${key} type='hidden'>
                                                                                                                                    <input id='projectId' value=${project._id} type='hidden'>
                                                                                                                                        <label class='form-account__label' for='updateTitle'>Stakeholder</label>
                                                                                                                                        <input id='updateTitle' class='form-account__input' type='text' name='updateTitle' value=${updateTitle}></div>
                                                                                                                                        <div class='form-account__group ma-bt-sm'>
                                                                                                                                            <div class='row'>
                                                                                                                                                <label class='form-account__label' for='stakeholderEvidenceOfOutcome'>Stakeholder Evidence of Outcome (optional)</label><textarea id='stakeholderEvidenceOfOutcome' class='form-account__textarea' type='text' name='stakeholderEvidenceOfOutcome'>${stakeholderEvidenceOfOutcome}</textarea>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        <div class='row'>
                                                                                                                                            <div class='row-subgroup'>
                                                                                                                                                <div class='col-1-of-2'>
                                                                                                                                                    <label class='form-account__label-subgroup' for='subgroupEdit'>Are there sub-groups?</label>
                                                                                                                                                </div>
                                                                                                                                                <div class='col-1-of-2'>
                                                                                                                                                    <div class='col-1-of-2'>
                                                                                                                                                        <input id='editpositive' class='form__radio-input' type='radio' name='subgroupEdit' value='editpositive' />
                                                                                                                                                        <label class='form__radio-label' for='editpositive'>
                                                                                                                                                            <span class='form__radio-button'></span>Yes</label>
                                                                                                                                                    </div>
                                                                                                                                                    <div class='col-1-of-2'>
                                                                                                                                                        <input id='editnegative' class='form__radio-input' type='radio' name='subgroupEdit' value='editnegative' checked=checked />
                                                                                                                                                        <label class='form__radio-label' for='editnegative'>
                                                                                                                                                            <span class='form__radio-button'></span>No</label>
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            <div class='row'>
                                                                                                                                                <div class='subgroupEdit-accordion'>
                                                                                                                                                    <div class='row-subgroup'>
                                                                                                                                                        <div class='col-1-of-2'>
                                                                                                                                                            <div class='col-2-of-3'>
                                                                                                                                                                <label class='form-account__label' for='subGroupOneEdit'>Subgroup 1</label><input id='subGroupOneEdit' class='form-account__input-subgroup' type='text' name='subGroupOneEdit'></div>
                                                                                                                                                            <div class='col-1-of-3'>
                                                                                                                                                                <label class='form-account__label' for='subGroupOneEditPercent'>Number</label>
                                                                                                                                                                <input id='subGroupOneEditPercent' class='form-account__input-subgroupPercent' type='number' name='subGroupOneEditPercent'></div>
                                                                                                                                                        </div>
                                                                                                                                                        <div class='col-1-of-2'>
                                                                                                                                                            <div class='col-2-of-3'>
                                                                                                                                                                <label class='form-account__label' for='subGroupThreeEdit'>Subgroup 3</label><input id='subGroupThreeEdit' class='form-account__input-subgroup' type='text' name='subGroupThreeEdit'></div>
                                                                                                                                                            <div class='col-1-of-3'>
                                                                                                                                                                <label class='form-account__label' for='subGroupThreeEditPercent'>Number</label>
                                                                                                                                                                <input id='subGroupThreeEditPercent' class='form-account__input-subgroupPercent' type='number' name='subGroupThreeEditPercent'></div>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                    <div class='row'>
                                                                                                                                                        <div class='col-1-of-2'>
                                                                                                                                                            <div class='col-2-of-3'>
                                                                                                                                                                <label class='form-account__label' for='subGroupTwoEdit'>Subgroup 2</label><input id='subGroupTwoEdit' class='form-account__input-subgroup' type='text' name='subGroupTwoEdit'></div>
                                                                                                                                                            <div class='col-1-of-3'>
                                                                                                                                                                <label class='form-account__label' for='subGroupTwoEditPercent'>Number</label>
                                                                                                                                                                <input id='subGroupTwoEditPercent' class='form-account__input-subgroupPercent' type='number' name='subGroupTwoEditPercent'></div>
                                                                                                                                                        </div>
                                                                                                                                                        <div class='col-1-of-2'>
                                                                                                                                                            <div class='col-2-of-3'>
                                                                                                                                                                <label class='form-account__label' for='subGroupFourEdit'>Subgroup 4</label><input id='subGroupFourEdit' class='form-account__input-subgroup' type='text' name='subGroupFourEdit'></div>
                                                                                                                                                            <div class='col-1-of-3'>
                                                                                                                                                                <label class='form-account__label' for='subGroupFourEditPercent'>Number</label>
                                                                                                                                                                <input id='subGroupFourEditPercent' class='form-account__input-subgroupPercent' type='number' name='subGroupFourEditPercent'></div>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                                <a class='modal__close' href=''>&times;</a>
                                                                                                                                                <div class='form-account__group right' style='margin-top: 1rem'>
                                                                                                                                                    <button class='btn-account btn--small btn--green btn--update-stakeholder' type='submit'>Update Stakeholder</button>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </div></form>
                                                                                                                            </div>`
                ////////////// END OF REPLACEMENT POPUP WITHOUT IF, WHEN, POS/NEG ETC /////////////

                const slideDown = element => element.style.height = `${element.scrollHeight}px`;
                // if subgroup radio button is yes, show subgroup input boxes - otherwise, hide and clear
                document.getElementById('editpositive').addEventListener('click', async (e) => {
                    slideDown(document.querySelector('.subgroupEdit-accordion'))
                })
                document.getElementById('editnegative').addEventListener('click', async (e) => {
                    document.querySelector('.subgroupEdit-accordion').style.height = '0'

                    // clear values from the form when no sub-groups
                    document.getElementById('subGroupOneEdit').value = ''
                    document.getElementById('subGroupOneEditPercent').value = ''
                    document.getElementById('subGroupTwoEdit').value = ''
                    document.getElementById('subGroupTwoEditPercent').value = ''
                    document.getElementById('subGroupThreeEdit').value = ''
                    document.getElementById('subGroupThreeEditPercent').value = ''
                    document.getElementById('subGroupFourEdit').value = ''
                    document.getElementById('subGroupFourEditPercent').value = ''
                })
            }

            let updateStakeholderForm = document.querySelector('.form-update-stakeholder')

            document.querySelector('.modal__close').addEventListener('click', (ev) => {
                ev.preventDefault()
                modal.style.display = 'none'
            })

            // If the update Target form is submitted, send to API
            if (updateStakeholderForm) {
                // alert(JSON.parse(document.getElementById('evidenceOfOutcome').value))
                updateStakeholderForm.addEventListener('submit', updateStakeholderComponent)
            }
        })
        // Add the stakeholders to the myStakeholders element in the DOM
        myStakeholders.appendChild(button)
    })
}

if (contactForm) {
    contactForm.addEventListener('submit', (e) => {
        e.preventDefault()
        const message = document.getElementById('message').value
        const customerEmail = document.getElementById('email').value
        document.getElementById('customerContact').textContent = 'Sending...'

        submitContact(customerEmail, message)
    })
}

if (loginForm) {
    loginForm.addEventListener('submit', (e) => {
        e.preventDefault()
        const email = document.getElementById('email').value
        const password = document.getElementById('password').value
        login(email, password)
    })
}

if (forgotPasswordForm) {
    forgotPasswordForm.addEventListener('submit', (e) => {
        e.preventDefault()
        const email = document.getElementById('email').value
        document.querySelector('.btn--green').textContent = 'Sending...'
        forgotPassword(email)
    })
}

if (resetPasswordForm) {
    resetPasswordForm.addEventListener('submit', (e) => {
        e.preventDefault()
        const password = document.getElementById('newPassword').value
        const passwordConfirm = document.getElementById('passwordConfirm').value
        const resetToken = document.getElementById('resetToken').value
        // console.log(password, passwordConfirm, resetToken)
        resetPassword(password, passwordConfirm, resetToken)
    })
}

if (logOutBtn) logOutBtn.addEventListener('click', logout)

if (verify2faForm) {
    verify2faForm.addEventListener('submit', (e) => {
        e.preventDefault()
        const totpCode = document.getElementById('code').value;
        verify2fa(totpCode)
    })
}

if (userDataForm) {
    userDataForm.addEventListener('submit', (e) => {
        e.preventDefault()
        const name = document.getElementById('name').value
        const email = document.getElementById('email').value
        updateSettings({ name, email }, 'data')
    })
}

if (userPasswordForm) {
    userPasswordForm.addEventListener('submit', async (e) => {
        e.preventDefault()
        document.querySelector('.btn--save-password').textContent = 'Updating...'

        const passwordCurrent = document.getElementById('password-current').value
        const password = document.getElementById('password').value
        const passwordConfirm = document.getElementById('password-confirm').value
        await updateSettings({ passwordCurrent, password, passwordConfirm }, 'password')

        document.querySelector('.btn--save-password').textContent = 'Save Password'
        document.getElementById('password-current').value = ''
        document.getElementById('password').value = ''
        document.getElementById('password-confirm').value = ''
    })
}

if (createProjectForm) {
    // to update the discount rate span value on the fly 
    let discountRateDisplay = document.getElementById('discountRateValue')
    let discountRateRange = document.getElementById('discountRate')

    discountRateDisplay.innerHTML = discountRateRange.value + '%'

    discountRateRange.oninput = function () {
        discountRateDisplay.innerHTML = this.value + '%'
    }

    createProjectForm.addEventListener('submit', async (e) => {
        e.preventDefault()
        document.querySelector('.btn--create-project').textContent = 'Creating...'

        const name = document.getElementById('title').value
        const discountRate = document.getElementById('discountRate').value
        const currency = document.getElementById('currency').value
        const type = document.querySelector('input[name = "forecast"]:checked').value

        await createProject({ name, discountRate, currency, type })
    })
}

if (createProjectScope) {
    // to give a character count on the page
    const textAreaCounter = function (textAreaIdentifier, outputText) {
        textAreaIdentifier.addEventListener('input', event => {
            const target = event.currentTarget
            const maxLength = target.getAttribute('maxlength')
            const currentLength = target.value.length
            // outputText.innerHTML = `${maxLength - currentLength}`

            if (currentLength >= maxLength) {
                // return console.log("You have reached the maximum number of characters.")
                return outputText.innerHTML = "You have reached the maximum number of characters"
            }
            // console.log(`${maxLength - currentLength} characters left`)
            outputText.innerHTML = (`${maxLength - currentLength} characters remaining`)
        })
    }
    // grab the elements
    const textAreaObjective = document.getElementById('objectives')
    const objectiveCount = document.getElementById('objectiveCount')
    const textAreaActivity = document.getElementById('activity')
    const activityCount = document.getElementById('activityCount')

    textAreaCounter(textAreaObjective, objectiveCount)
    textAreaCounter(textAreaActivity, activityCount)

    // to update the discount rate span value on the fly 
    let discountRateDisplayUpdate = document.getElementById('discountRateValueUpdate')
    let discountRateRangeUpdate = document.getElementById('discountRateUpdate')

    discountRateDisplayUpdate.innerHTML = discountRateRangeUpdate.value + '%'

    discountRateRangeUpdate.oninput = function () {
        discountRateDisplayUpdate.innerHTML = this.value + '%'
    }

    createProjectScope.addEventListener('submit', async (e) => {
        e.preventDefault()

        document.querySelector('.btn--create-scope').textContent = 'Saving...'

        const objectives = document.getElementById('objectives').value
        const activity = document.getElementById('activity').value
        const scopeOfAnalysis = document.getElementById('scopeOfAnalysis').value
        const purposeOfAnalysis = document.getElementById('purposeOfAnalysis').value
        const discountRate = document.getElementById('discountRateUpdate').value
        const framework = document.getElementById('framework').value
        const startDate = document.getElementById('startDate').value
        const endDate = document.getElementById('endDate').value
        const name = document.getElementById('projectTitle').value
        const type = document.querySelector('input[name = "forecast"]:checked').value
        const extent = document.getElementById('extent').value
        // const stage = document.getElementById('projectStage').value;
        const archive = document.getElementById('archive').value;

        const projectId = document.getElementById('projectId').value

        function extractDirectorateData() {
            const directorateElement = document.getElementById('directorate');
            if (directorateElement) {
                const directorateId = directorateElement.value;
                const directorateTitle =
                    directorateElement.options[directorateElement.selectedIndex].text;
                return { directorateId, directorateTitle };
            }
            return null;
        }
        function extractDivisionData() {
            const divisionElement = document.getElementById('division');
            if (divisionElement) {
                const divisionId = divisionElement.value;
                const divisionTitle =
                    divisionElement.options[divisionElement.selectedIndex].text;
                return { divisionId, divisionTitle };
            }
            return null;
        }
        function extractSupplierData() {
            const supplierElement = document.getElementById('supplier');
            if (supplierElement) {
                const supplierId = supplierElement.value;
                // return { supplierId };
                return supplierId;
            }
            return null;
        }
        const directorateData = extractDirectorateData();
        const divisionData = extractDivisionData();
        const supplierData = extractSupplierData();

        const formData = {
            objectives, activity, scopeOfAnalysis, purposeOfAnalysis, discountRate, framework, startDate, endDate, name, type, archive, extent,
            ...(directorateData ? { directorate: directorateData } : {}),
            ...(divisionData ? { division: divisionData } : {}),
            ...(supplierData ? { supplier: supplierData } : {})
        }
        await updateProject(projectId, formData)
        location.reload()
    })
}

if (deleteProjectForm) {
    deleteProjectForm.addEventListener('submit', async (e) => {
        e.preventDefault()
        document.querySelector('.btn--delete-project').textContent = 'Deleting...'
        let projectId = document.getElementById('deleteProjectId').value

        await deleteProject(projectId)
    })
}

if (duplicateProjectForm) {
    duplicateProjectForm.addEventListener('submit', duplicateProjectComponent)
}

if (createTargetForm) {
    createTargetForm.addEventListener('submit', async (e) => {
        e.preventDefault()
        document.querySelector('.btn--create-target').textContent = 'Creating...'

        const title = document.getElementById('targetTitle').value
        const targetForecast = document.getElementById('targetForecast').value
        const targetDescription = document.getElementById('targetDescription').value
        const targetCategory = document.getElementById('targetCategory').value
        const project = document.getElementById('projectId').value

        await createTarget({ title, targetForecast, targetDescription, targetCategory, project })
        location.reload()

    })
}

if (createStakeholderForm) {
    // First show or hide the subgroup inputs depending on the radio button selection
    // calculate the height of the element
    const slideDown = element => element.style.height = `${element.scrollHeight}px`;
    // if subgroup radio button is yes, show subgroup input boxes - otherwise, hide and clear
    document.getElementById('positive').addEventListener('click', async (e) => {
        // document.querySelector('.subgroup-accordion').style.display = 'block'
        slideDown(document.querySelector('.subgroup-accordion'))
    })
    document.getElementById('negative').addEventListener('click', async (e) => {
        // document.querySelector('.subgroup-accordion').style.display = 'none'
        document.querySelector('.subgroup-accordion').style.height = '0'

        // clear values from the form when no sub-groups
        document.getElementById('subgroup-one').value = ''
        document.getElementById('subgroup-percent-one').value = ''
        document.getElementById('subgroup-two').value = ''
        document.getElementById('subgroup-percent-two').value = ''
        document.getElementById('subgroup-three').value = ''
        document.getElementById('subgroup-percent-three').value = ''
        document.getElementById('subgroup-four').value = ''
        document.getElementById('subgroup-percent-four').value = ''
    })

    createStakeholderForm.addEventListener('submit', async (e) => {
        e.preventDefault()
        // document.querySelector('.btn--create-stakeholder').textContent = 'Creating...'
        const project = document.getElementById('projectId').value

        const includeSubgroup = document.querySelector('input[name = "subgroup"]:checked').value

        // check if subgroups included is checked or not before submitting the stakeholder
        if (includeSubgroup === 'negative') {
            const stakeholders = {
                stakeholderTitle: document.getElementById('stakeholderTitle').value,
                primary: document.querySelector('input[name = "primary"]:checked').value,
                evidenceOfOutcome: document.getElementById('evidenceOfOutcome').value,
                stakeholderInvolvement: document.getElementById('stakeholderInvolvement').value,
                stakeholderInclusion: document.getElementById('stakeholderInclusion').value,
                population: document.getElementById('stakeholderPopulation').value,
                // reasonForInclusion: document.getElementById('reasonForInclusion').value,
                // positiveNegative: document.getElementById('positiveNegative').value,
                // method: document.getElementById('method').value,
                // when: document.getElementById('when').value
                // project: document.getElementById('projectId').value
            }
            // const stakeholderFormResult = await createStakeholder({ stakeholders, project });
            await createStakeholder({ stakeholders, project });
            // if (stakeholderFormResult.reloadForm) {
            //     alert('Ok')
            // } else {
            //     showAlert('error', result.message || 'An error occurred while submitting the form');
            // }
            // window.setTimeout(() => {
            //     location.reload()
            // }, 1000)
        } else {
            // console.log(document.querySelector('input[name = "primary"]:checked').value)
            const subgroup = [
                {
                    subgroupTitle: document.getElementById('subgroup-one').value,
                    subgroupPercent: document.getElementById('subgroup-percent-one').value
                },
                {
                    subgroupTitle: document.getElementById('subgroup-two').value,
                    subgroupPercent: document.getElementById('subgroup-percent-two').value
                },
                {
                    subgroupTitle: document.getElementById('subgroup-three').value,
                    subgroupPercent: document.getElementById('subgroup-percent-three').value
                },
                {
                    subgroupTitle: document.getElementById('subgroup-four').value,
                    subgroupPercent: document.getElementById('subgroup-percent-four').value
                }
            ]

            // commenting out the below so that subgroups can be over 100 on the front-end
            // if (Number(subgroup[0].subgroupPercent) + Number(subgroup[1].subgroupPercent) + Number(subgroup[2].subgroupPercent) + Number(subgroup[3].subgroupPercent) > 100) {
            //     showAlert('error', 'Sub-group total cannot exceed 100%')
            //     return false
            // }

            const stakeholders = {
                stakeholderTitle: document.getElementById('stakeholderTitle').value,
                primary: document.querySelector('input[name = "primary"]:checked').value,
                evidenceOfOutcome: document.getElementById('evidenceOfOutcome').value,
                stakeholderInvolvement: document.getElementById('stakeholderInvolvement').value,
                stakeholderInclusion: document.getElementById('stakeholderInclusion').value,
                population: document.getElementById('stakeholderPopulation').value,
                // reasonForInclusion: document.getElementById('reasonForInclusion').value,
                // positiveNegative: document.getElementById('positiveNegative').value,
                // method: document.getElementById('method').value,
                // when: document.getElementById('when').value,
                subgroup: subgroup
                // project: document.getElementById('projectId').value
            }
            await createStakeholder({ stakeholders, project })
            // window.setTimeout(() => {
            //     location.reload()
            // }, 1000)
        }
    })
}

if (createIndicatorForm) {
    createIndicatorForm.addEventListener('submit', async (e) => {
        e.preventDefault()
        document.querySelector('.btn--create-indicator').textContent = 'Creating...'

        const title = document.getElementById('indicatorTitle').value
        const description = document.getElementById('indicatorDescription').value
        const subjective = document.querySelector('input[name = "subjective"]:checked').value

        const proxy = document.getElementById('indicatorProxy');
        const option = proxy.options[proxy.selectedIndex]

        const proxyTitle = option.getAttribute('data-outcomeTitle');
        // const value = proxy.options[proxy.selectedIndex].value;

        const outcomeId = proxy.options[proxy.selectedIndex].value;
        // const value = option.getAttribute('data-proxyValue')
        // const unit = option.getAttribute('data-proxyUnit')
        const bristol = option.getAttribute('data-proxyBristol')
        // const outcome = option.getAttribute('data-proxyOutcome')
        // const proxyTitle = option.getAttribute('data-proxyTitle')
        // const anchor = option.getAttribute('data-proxyAnchor')
        // const proxySource = option.getAttribute('data-proxySource')

        // const stakeholder = document.getElementById('indicatorStakeholder').value
        const indicatorQuantity = document.getElementById('indicatorQuantity').value
        const benchmark = document.getElementById('indicatorBenchmark').value
        const measurementUnit = document.getElementById('indicatorMeasurementUnit').value
        const baseline = document.getElementById('indicatorBaseline').value
        const measurement = document.getElementById('indicatorMeasurementMethod').value
        const indicatorURL = document.getElementById('indicatorURL').value

        const project = document.getElementById('projectId').value

        // await createIndicator({ title, description, subjective, value, unit, bristol, outcome, proxyTitle, anchor, proxySource, indicatorQuantity, benchmark, measurementUnit, baseline, measurement, indicatorURL, project })
        await createIndicator({ title, description, subjective, outcomeId, bristol, proxyTitle, indicatorQuantity, benchmark, measurementUnit, baseline, measurement, indicatorURL, project })
        location.reload()
    })
}


if (createOutputForm) {
    const outcomeLocation = document.getElementById('location');
    const dropdownContainer = document.querySelector("#location-container");
    if (outcomeLocation) {
        getLocation(outcomeLocation, dropdownContainer, {
            dropdownClass: "dropdown",
            listClass: "dropdown-list",
            itemClass: "dropdown-item"
        });
    }
    const selected = document.querySelector('.selected')
    const optionsContainer = document.querySelector('.options-container')
    const optionsList = document.querySelectorAll('.option')
    const searchBox = document.querySelector('.search-box input')
    // const hcaSelector = document.getElementById('hca');


    selected.addEventListener('click', () => {
        optionsContainer.classList.toggle('active')

        searchBox.value = ''
        filterList('')

        if (optionsContainer.classList.contains('active')) {
            searchBox.focus()
        }
    })
    optionsList.forEach(o => {
        o.addEventListener('click', () => {
            selected.innerHTML = o.querySelector('label').innerHTML
            optionsContainer.classList.remove('active')
        })
    })
    searchBox.addEventListener('keyup', (e) => {
        filterList(e.target.value)
    })
    const filterList = searchTerm => {
        searchTerm = searchTerm.toLowerCase()
        optionsList.forEach(option => {
            let label = option.firstElementChild.nextElementSibling.innerText.toLowerCase()
            if (label.indexOf(searchTerm) != -1) {
                option.style.display = 'block'
            } else {
                option.style.display = 'none'
            }
        })
    }
    // HCA eventlistener is below
    hcaDropdown.addEventListener('change', function () {
        deadweightDisplay.innerHTML = this.value + '%';
        deadweightRange.value = this.value;
    });
    createOutputForm.addEventListener('submit', async (e) => {
        e.preventDefault()
        document.querySelector('.btn--create-output').textContent = 'Creating...'

        let dg
        const title = document.getElementById('outputTitle').value
        const option = document.querySelector('input[name = "proxy"]:checked')

        if (!option) {
            showAlert('error', 'Please select a proxy')
            document.querySelector('.btn--create-output').textContent = 'Create Output'
        }

        const value = option.getAttribute('data-proxyValue')
        const unit = option.getAttribute('data-proxyUnit')
        const bristol = option.getAttribute('data-proxyBristol')
        const sdg = option.getAttribute('data-proxySDG')
        const barns = option.getAttribute('data-proxyBarns')
        const outcome = option.getAttribute('data-proxyOutcome')
        const proxyTitle = option.getAttribute('data-proxyTitle')
        const anchor = option.getAttribute('data-proxyAnchor')
        const source = option.getAttribute('data-proxySource')

        const user = document.getElementById('userId').value

        if (DGusers.includes(user)) {
            dg = option.getAttribute('data-proxyDG')
        }

        // const indicators = document.getElementById('indicators').value

        const quantity = document.getElementById('quantity').value
        const stakeholder = document.getElementById('stakeholder').value
        const timePeriod = document.getElementById('years').value
        const leakage = document.getElementById('leakage').value
        const deadweight = document.getElementById('deadweight').value
        const attribution = document.getElementById('attribution').value
        const dropOff = document.getElementById('dropOff').value
        const displacement = document.getElementById('displacement').value
        const hcaElement = document.getElementById('hca')
        const hcaValue = hcaElement.value
        const hcaText = hcaElement.options[hcaElement.selectedIndex].text;
        const hcaRef = hcaElement.options[hcaElement.selectedIndex].dataset.hcaref;
        const ownFrameworkEl = document.getElementById('ownFramework')
        let ownFramework;
        if (ownFrameworkEl) {
            ownFramework = ownFrameworkEl.value
        } else {
            ownFramework = 0;
        }
        const hca = { hcaValue, hcaText, hcaRef };
        const geocodeEl = document.getElementById('location');
        let geocode
        if (geocodeEl) {
            geocode = {
                locationId: geocodeEl.getAttribute('data-location-id'),
                lat: geocodeEl.getAttribute('data-lat'),
                lng: geocodeEl.getAttribute('data-lng')
            };
        }
        // const value = document.getElementById('outputValue').value
        const project = document.getElementById('projectId').value;

        const extent = document.getElementById('extent').value;

        let userOutcome = '';
        let deflatorDescription = '';
        let intended
        let startDate
        let endDate
        let afterDuring
        let positiveNegative

        if (extent == 'extensive') {
            userOutcome = document.getElementById('userOutcome').value
            deflatorDescription = document.getElementById('deflatorDesc').value
            intended = document.querySelector('input[name = "intention"]:checked').value
            startDate = document.getElementById('startDate').value
            endDate = document.getElementById('endDate').value
            afterDuring = document.querySelector('input[name = "after"]:checked').value
            positiveNegative = document.querySelector('input[name = "positive"]:checked').value
        };
        console.log(ownFramework)
        // this might be the point at which to differentiate D&G from other
        await createOutput({ title, quantity, stakeholder, timePeriod, leakage, deadweight, attribution, dropOff, displacement, value, unit, intended, userOutcome, deflatorDescription, anchor, source, bristol, sdg, dg, barns, outcome, proxyTitle, startDate, endDate, afterDuring, positiveNegative, hca, ownFramework, geocode, project })
        location.reload()
    })
}

if (createInputForm) {
    const inputLocation = document.getElementById('location');
    const dropdownContainer = document.querySelector("#location-container");
    if (inputLocation) {
        getLocation(inputLocation, dropdownContainer, {
            dropdownClass: "dropdown",
            listClass: "dropdown-list",
            itemClass: "dropdown-item"
        });
    }
    createInputForm.addEventListener('submit', async (e) => {
        e.preventDefault()
        document.querySelector('.btn--create-input').textContent = 'Creating...'

        const title = document.getElementById('inputTitle').value
        const category = document.getElementById('inputCategory').value
        const volume = document.getElementById('inputVolume').value
        const value = document.getElementById('inputValue').value
        const stakeholder = document.getElementById('stakeholder').value
        const inputToActivity = document.getElementById('inputToActivity').value
        // const inputNotes = document.getElementById('inputNotes').value
        const project = document.getElementById('projectId').value
        const geocodeEl = document.getElementById('location');
        let geocode
        if (geocodeEl) {
            geocode = {
                locationId: geocodeEl.getAttribute('data-location-id'),
                lat: geocodeEl.getAttribute('data-lat'),
                lng: geocodeEl.getAttribute('data-lng')
            };
            // return geocode
        }
        await createInput({ title, category, volume, value, stakeholder, inputToActivity, geocode, project })
        location.reload()
    })
}

if (createOutputTwoForm) {
    createOutputTwoForm.addEventListener('submit', async (e) => {
        e.preventDefault()
        document.querySelector('.btn--create-outputTwo').textContent = 'Creating...'

        const title = document.getElementById('outputTwoTitle').value
        const description = document.getElementById('outputTwoDescription').value
        const volume = document.getElementById('outputTwoVolume').value
        const stakeholder = document.getElementById('stakeholder').value
        const outputToActivity = document.getElementById('outputToActivity').value
        const project = document.getElementById('projectId').value
        // console.log(outputToActivity)

        await createOutputTwo({ title, volume, stakeholder, description, outputToActivity, project });
        location.reload()
    })
}

if (createKpiForm) {
    const kpiLocation = document.getElementById('location');
    const dropdownContainer = document.querySelector("#location-container");
    if (kpiLocation) {
        getLocation(kpiLocation, dropdownContainer, {
            dropdownClass: "dropdown",
            listClass: "dropdown-list",
            itemClass: "dropdown-item"
        });
    }
    createKpiForm.addEventListener('submit', async (e) => {
        e.preventDefault()
        document.querySelector('.btn--create-kpi').textContent = 'Creating...'

        const kpiDropdown = document.getElementById('kpi');
        const kpiId = kpiDropdown.value;
        const kpiTitle = kpiDropdown.options[kpiDropdown.selectedIndex].text;
        const kpiForecast = document.getElementById('kpiForecast').value
        const project = document.getElementById('projectId').value
        const geocodeEl = document.getElementById('location');
        let geocode
        if (geocodeEl) {
            geocode = {
                locationId: geocodeEl.getAttribute('data-location-id'),
                lat: geocodeEl.getAttribute('data-lat'),
                lng: geocodeEl.getAttribute('data-lng')
            };
        }
        await createKpi({ kpiId, kpiTitle, kpiForecast, geocode, project });
        location.reload()
    })
}

if (createConclusionForm) {
    // to give a character count on the page
    const textAreaCounter = function (textAreaIdentifier, outputText) {
        textAreaIdentifier.addEventListener('input', event => {
            const target = event.currentTarget
            const maxLength = target.getAttribute('maxlength')
            const currentLength = target.value.length
            // outputText.innerHTML = `${maxLength - currentLength}`

            if (currentLength >= maxLength) {
                // return console.log("You have reached the maximum number of characters.")
                return outputText.innerHTML = "You have reached the maximum number of characters"
            }

            // console.log(`${maxLength - currentLength} characters left`)
            outputText.innerHTML = (`${maxLength - currentLength} characters remaining`)
        })
    }
    // grab the elements
    const textAreaInsights = document.getElementById('insights')
    const insightCount = document.getElementById('insightCount')
    const textAreaRecommendations = document.getElementById('recommendations')
    const recommendationCount = document.getElementById('recommendationCount')
    const textAreaRegister = document.getElementById('register')
    const registerCount = document.getElementById('registerCount')

    textAreaCounter(textAreaInsights, insightCount)
    textAreaCounter(textAreaRecommendations, recommendationCount)
    textAreaCounter(textAreaRegister, registerCount)

    createConclusionForm.addEventListener('submit', async (e) => {
        e.preventDefault()

        document.querySelector('.btn--create-conclusion').textContent = 'Saving...'

        const insights = document.getElementById('insights').value
        const recommendations = document.getElementById('recommendations').value
        const register = document.getElementById('register').value
        const fitForPurpose = document.getElementById('fitForPurpose').value

        const projectId = document.getElementById('projectId').value

        await updateProject(projectId, { insights, recommendations, register, fitForPurpose })
        location.reload()
    })
}

if (createOwnProxyForm) {
    createOwnProxyForm.addEventListener('submit', async (e) => {
        e.preventDefault()
        let proxies = []

        // differentiate between the € form that includes the bristol manually
        let proxyBristolRef
        if (document.getElementById('outcomeRefNumber')) {
            proxyBristolRef = document.getElementById('outcomeRefNumber').value
        } else {
            proxyBristolRef = 10
        }

        let proxySdgRef
        if (document.getElementById('sdgRefNumber')) {
            proxySdgRef = document.getElementById('sdgRefNumber').value
        } else {
            proxySdgRef = 0
        }

        let proxyDgRef
        if (document.getElementById('dgRefNumber')) {
            proxyDgRef = document.getElementById('dgRefNumber').value
        } else {
            proxyDgRef = 0
        }

        let proxyBarnsRef
        if (document.getElementById('dgBarnsNumber')) {
            proxyBarnsRef = document.getElementById('dgBarnsNumber').value
        } else {
            proxyBarnsRef = 0
        }
        // let proxyDescription
        // let proxyValuationTechnique
        proxies.push({
            proxyTitle: document.getElementById('ownProxyTitle').value,
            proxyOutcome: document.getElementById('ownProxyOutcome').value,
            proxyValue: document.getElementById('ownProxyValue').value,
            // proxyBristol: 10,
            proxyBristol: proxyBristolRef,
            proxySDG: proxySdgRef,
            proxyDG: proxyDgRef,
            proxyBarns: proxyBarnsRef,
            proxyUnit: document.getElementById('ownProxyUnit').value,
            proxyAnchor: document.getElementById('sourceDescription').value,
            proxySource: document.getElementById('sourceURL').value,
            proxyDescription: document.getElementById('proxyDescription').value,
            proxyValuationTechnique: document.getElementById('valuationTechnique').value
        });
        // alert(proxyDescription, proxyValuationTechnique)

        const project = document.getElementById('projectId').value
        JSON.stringify(proxies)

        await addProxy({ proxies, project })
    })
}

if (searchText) {
    let proxyTemplate = document.getElementById('proxy-template').cloneNode(true)
    // let list = proxyTemplate.parentNode;
    proxyTemplate.remove(); // Or on older browsers: template.parentNode.removeChild(template);
    proxyTemplate.removeAttribute('id');
    proxyTemplate.removeAttribute('style')

    searchText.addEventListener("keyup", async (e) => {
        const TIMEOUT = 100
        let timeoutId
        const searchText = e.target.value;
        clearTimeout(timeoutId)
        timeoutId = setTimeout(function () {
            const url = '/proxies?searchText=' + searchText;
            fetch(url).then(response => response.json())
                // we are returning the correct proxies here
                .then(proxies => renderProxies(proxies))
        }, TIMEOUT)

        const renderProxies = function (proxies) {
            container.innerHTML = ''
            for (const idx in proxies) {
                const proxy = proxies[idx];
                const proxyClone = proxyTemplate.cloneNode(true)
                const input = proxyClone.querySelector('.proxy-input')

                //populate the clone, set the values
                proxyClone.querySelector('.proxy-title').textContent = `${proxy.outcome} || ${proxy.title} || ${proxy.unit}`
                input.setAttribute('data-title', proxy.title)
                input.setAttribute('data-value', proxy.value)
                input.setAttribute('data-bristol', proxy.bristol)
                input.setAttribute('data-sdg', proxy.sdg)
                input.setAttribute('data-dg', proxy.dg)
                input.setAttribute('data-barns', proxy.barns)
                input.setAttribute('data-outcome', proxy.outcome)
                input.setAttribute('data-unit', proxy.unit)
                input.setAttribute('data-anchor', proxy.anchor)
                input.setAttribute('data-source', proxy.source)

                container.appendChild(proxyClone)

            }
        }
    })
}

if (selectedProxyBox) {
    const deleteProxyButtons = document.querySelectorAll('.btn--delete-proxy');
    deleteProxyButtons.forEach(function (button) {
        button.addEventListener('click', deleteProxyFromProjectComponent)
    });

    // const collapseButton = document.querySelector(".collapse-button");
    const collapseButton = document.getElementById("collapse-button");
    const collapsible = selectedProxyBox;

    collapseButton.addEventListener("click", function () {
        if (collapsible.classList.contains("collapsed")) {
            collapsible.classList.remove("collapsed");
            collapseButton.classList.remove("maximise-icon");
            collapseButton.classList.add("collapse-icon");
            collapseButton.innerHTML = `<use xlink:href="/img/icons.svg#icon-minus"></use>`;
            // collapsible.style.overflowY = "scroll";
            collapsible.style.overflowY = "auto";
        } else {
            collapsible.classList.add("collapsed");
            collapseButton.classList.add("maximise-icon");
            collapseButton.innerHTML = `<use xlink:href="/img/icons.svg#icon-maximize-2"></use>`
            collapseButton.classList.remove("collapse-icon");
            // collapsible.style.overflowY = "hidden";
            collapsible.style.overflowY = "auto";
        }
    });
}

if (projectSearchInput) {
    function sroiRatio(rawSroi, currency) {
        if (rawSroi === Infinity) {
            return '<span class="card-project__footer-value">&infin;</span>';
        } else if ((isNaN(rawSroi) || rawSroi === null)) {
            return `<span class="card-project__footer-value">${currency}0.00</span>`;
        } else {
            const formattedSroi = (rawSroi).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
            return `<span class="card-project__footer-value">${currency}${formattedSroi}</span>`;
        }
    }
    const currentURL = window.location.href; // Get the current page URL
    const parts = currentURL.split('/'); // Split the URL by '/'
    const designation = parts[4]; // Retrieve the value at index 1 (assuming it's the second part)

    projectSearchInput.addEventListener('keyup', async (e) => {
        // alert('alert project seach')
        const TIMEOUT = 100
        let timeoutId
        const searchText = e.target.value.trim();
        clearTimeout(timeoutId)
        timeoutId = setTimeout(function () {
            const url = '/projectSearch/?searchText=' + searchText + '&designation=' + designation;;
            fetch(url).then(response => response.json())
                .then(data => {
                    // Process the data and update the UI with the search results
                    renderProjects(data)
                })
                .catch(error => {
                    console.log('An error occurred:', error);
                    // Handle the error
                });
        }, TIMEOUT)
        const renderProjects = function (data) {
            cardContainer.innerHTML = '';
            data.projects.forEach(project => {
                // Create the HTML markup for each project
                const html = `<div class="card-project">
                                                                                                                                <div class="card-project__header">
                                                                                                                                    <div class="card-project__picture">
                                                                                                                                        <div class="card-project__picture-overlay">&nbsp;</div>
                                                                                                                                        <img class="card-project__picture-img" src="/img/front/hero small.jpeg" alt="${project.name}">
                                                                                                                                    </div>
                                                                                                                                    <h3 class="heading-tertirary">
                                                                                                                                        <span>
                                                                                                                                            ${project.name.length < 40 ? project.name : project.name.substring(0, 40) + '...'}
                                                                                                                                        </span>
                                                                                                                                    </h3>
                                                                                                                                </div>
                                                                                                                                <div class="card-project__details">
                                                                                                                                    <h4 class="card-project__sub-heading">${project.type}</h4>
                                                                                                                                    <p class="card-project__text">
                                                                                                                                        <span>
                                                                                                                                            ${project.objectives.length < 40 ? project.objectives : project.objectives.substring(0, 40) + '...'}
                                                                                                                                        </span>
                                                                                                                                    </p>
                                                                                                                                    <div class="card-project__data">
                                                                                                                                        <svg class="card-project__icon">
                                                                                                                                            <use xlink:href="/img/icons.svg#icon-pie-chart"></use>
                                                                                                                                        </svg>
                                                                                                                                        <span>${project.discountRate}%</span>
                                                                                                                                    </div>
                                                                                                                                    <div class="card-project__data">
                                                                                                                                        <svg class="card-project__icon">
                                                                                                                                            <use xlink:href="/img/icons.svg#icon-calendar"></use>
                                                                                                                                        </svg>
                                                                                                                                        <span>${moment(project.createdAt).format('MMM Do YYYY')}</span>
                                                                                                                                    </div>
                                                                                                                                    <div class="card-project__data">
                                                                                                                                        <svg class="card-project__icon">
                                                                                                                                            <use xlink:href="/img/icons.svg#icon-flag"></use>
                                                                                                                                        </svg>
                                                                                                                                        <span>${project.outputs.length} outcomes</span>
                                                                                                                                    </div>
                                                                                                                                    <div class="card-project__data">
                                                                                                                                        <svg class="card-project__icon">
                                                                                                                                            <use xlink:href="/img/icons.svg#icon-user"></use>
                                                                                                                                        </svg>
                                                                                                                                        <span>${project.stakeholders.length} stakeholders</span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div class="card-project__footer">
                                                                                                                                    <p>
                                                                                                                                        <span class="card-project__footer-text">NPV:</span>
                                                                                                                                        <span class="card-project__footer-value">${project.currency}${(project.netPresentValue).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</span>
                                                                                                                                    </p>
                                                                                                                                    <p class="card-project__ratings">
                                                                                                                                        <span class="card-project__footer-text">${sroiRatio(project.sroiRatio, project.currency)} per ${project.currency}1</span>
                                                                                                                                    </p>
                                                                                                                                    <a class="btn-account btn--green btn-small__card" href="/project/${project._id}">Open</a>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            `
                const container = document.createElement('div');
                container.innerHTML = html;

                // Append the container element to the cardContainer
                cardContainer.appendChild(container);

            });
        }
        if (searchText === '') {
            paginationContainer.style.display = 'block';
        } else {
            paginationContainer.style.display = 'none';
        }
    })
}

if (addProxyForm) {
    addProxyForm.addEventListener('submit', async (e) => {
        e.preventDefault()
        document.querySelector('.btn--add-proxy').textContent = 'Adding...'
        const project = document.getElementById('projectId').value
        let proxies = []

        const checkboxes = document.querySelectorAll('.proxy-input:checked')
        const user = document.getElementById('userId').value
        // const DGusers = ['5c8a1e1a2f8fb814b56fa182', '6041ebb26c33723fa108ea42', '5fc10e6718f2ac6244e3e9c9', '5fc25f7218f2ac6244e3eb21', '5fc2619718f2ac6244e3eb2a', '5fc2649218f2ac6244e3eb37', '5fc6385d18f2ac6244e3ed17', '5fc6390718f2ac6244e3ed18', '614380beb6688f581869ee05', '614381a4b6688f581869ee06']

        for (const checkbox of Array.from(checkboxes)) {
            let proxyObject = {
                proxyTitle: checkbox.getAttribute('data-title'),
                proxyValue: checkbox.getAttribute('data-value'),
                proxyBristol: checkbox.getAttribute('data-bristol'),
                proxySDG: checkbox.getAttribute('data-sdg'),
                proxyBarns: checkbox.getAttribute('data-barns'),
                proxyOutcome: checkbox.getAttribute('data-outcome'),
                proxyUnit: checkbox.getAttribute('data-unit'),
                proxyAnchor: checkbox.getAttribute('data-anchor'),
                proxySource: checkbox.getAttribute('data-source')
            }
            // Check if user is Dumfries and Galloway or no
            if (DGusers.includes(user)) {
                proxyObject.proxyDG = checkbox.getAttribute('data-dg')
            }
            proxies.push(proxyObject)
        }
        JSON.stringify(proxies)
        await addProxy({ proxies, project })
    })
}

if (dropdownProxyExtras) {
    document.querySelector('.proxy-project__icon').addEventListener('click', async (e) => {
        document.getElementById('myDropdown').classList.toggle('show')
    })
    window.onclick = function (event) {
        if (!event.target.matches('.proxy-project__icon')) {
            let dropdowns = document.getElementsByClassName('dropdown-content')
            let i
            for (i = 0; i < dropdowns.length; i++) {
                let openDropdown = dropdowns[i]
                if (openDropdown.classList.contains('show')) {
                    openDropdown.classList.remove('show')
                }
            }
        }
    }
}

if (createProgrammeForm) {
    createProgrammeForm.addEventListener('submit', async (e) => {
        e.preventDefault()
        document.querySelector('.btn--create-programme').textContent = 'Creating...'

        const name = document.getElementById('programme-name').value
        let projects = []

        const checkboxes = document.querySelectorAll('.project-input:checked')

        for (const checkbox of Array.from(checkboxes)) {
            projects.push({
                _id: checkbox.getAttribute('data-projectId')
            })
        }
        // console.log({ name, projects })
        await createProgramme({ name, projects })
        location.reload()
    })
}

if (programmeContainer) {
    const deleteProgrammeButtons = document.querySelectorAll('.btn--delete-programme')

    deleteProgrammeButtons.forEach(function (button) {
        button.addEventListener('click', deleteProgrammeComponent)
    })
}

if (actualInputForm) {
    const inputLocation = document.getElementById('inputLocation');
    const dropdownContainer = document.querySelector("#input-location-list");
    getLocation(inputLocation, dropdownContainer, {
        dropdownClass: "outcome-actual-dropdown",
        listClass: "outcome-actual-dropdown-ul",
        itemClass: "outcome-actual-dropdown-item"
    });
    // const outcomeTotalElement = document.getElementById('outcomeActualTotal');
    // const inputTotalElement = document.getElementById('inputActualTotal');
    // const npvElement = document.getElementById('npvLedger');
    // const sroiElement = document.getElementById('actualSroiRatio');
    const project = document.getElementById('projectId').value

    actualInputForm.addEventListener('submit', async (e) => {
        e.preventDefault();
        const quantity = document.getElementById('quantity').value
        const cost = parseFloat(document.getElementById('inputValue').value)
        const inputParent = document.getElementById('inputId')
        const selectedOption = inputParent.options[inputParent.selectedIndex];
        const input = selectedOption.value
        const inputTitle = selectedOption.textContent;
        const date = document.getElementById('inputDate').value
        let geocodeEl = document.getElementById('inputLocation');
        let geocode;
        if (geocodeEl) {
            geocode = {
                locationId: geocodeEl.getAttribute('data-location-id'),
                lat: geocodeEl.getAttribute('data-lat'),
                lng: geocodeEl.getAttribute('data-lng')
            };
        }
        createInputActual({ quantity, cost, input, inputTitle, date, project, geocode })
        await updateProject(project)
        actualInputForm.reset();
        // updateLedger(ledger, project, inputTotalElement, outcomeTotalElement, npvElement, sroiElement)
        // await updateLedger(ledger, project, inputTotalElement, outcomeTotalElement, npvElement)
        await fetchDataAndUpdateUI(project, ledger)
    })
}

if (actualOutcomeForm) {
    const outcomeLocation = document.getElementById('outcomeLocation');
    const dropdownContainer = document.querySelector("#outcome-location-list");
    getLocation(outcomeLocation, dropdownContainer, {
        dropdownClass: "input-actual-dropdown",
        listClass: "input-actual-dropdown-ul",
        itemClass: "input-actual-dropdown-item"
    });
    // const inputTotalElement = document.getElementById('inputActualTotal')
    // const outcomeTotalElement = document.getElementById('outcomeActualTotal')
    // const npvElement = document.getElementById('npvLedger');
    // const sroiElement = document.getElementById('actualSroiRatio');
    const project = document.getElementById('projectId').value
    actualOutcomeForm.addEventListener('submit', async (e) => {
        e.preventDefault();
        const quantity = document.getElementById('outcomeQuantity').value
        const outputId = document.getElementById('outcomeId').value
        const outputParentData = document.getElementById('outcomeId')
        const selectedOption = outputParentData.options[outputParentData.selectedIndex];
        const outputUnit = selectedOption.dataset.unit;
        const outputTitle = selectedOption.dataset.title;
        const outputValue = selectedOption.dataset.outputvalue;
        const outputTimePeriod = selectedOption.dataset.timeperiod;
        const outputLeakage = selectedOption.dataset.leakage;
        const outputAttribution = selectedOption.dataset.attribution;
        const outputDeadweight = selectedOption.dataset.deadweight;
        const outputDropOff = selectedOption.dataset.dropoff;
        const outputDisplacement = selectedOption.dataset.displacement;
        let sdgEl = selectedOption.dataset.sdg;
        let outputSdg;
        if (sdgEl !== 'undefined') {
            outputSdg = selectedOption.dataset.sdg;
        }
        let ownFrameworkEl = selectedOption.dataset.ownframework;
        let outputOwnFramework;
        if (ownFrameworkEl !== 'undefined') {
            outputOwnFramework = selectedOption.dataset.ownframework;
        }
        const date = document.getElementById('outcomeDate').value
        let geocodeEl = document.getElementById('outcomeLocation');
        let geocode;
        if (geocodeEl) {
            geocode = {
                locationId: geocodeEl.getAttribute('data-location-id'),
                lat: geocodeEl.getAttribute('data-lat'),
                lng: geocodeEl.getAttribute('data-lng')
            };
        }
        createOutcomeActual({ quantity, date, outputId, outputTitle, outputUnit, outputValue, outputTimePeriod, outputLeakage, outputAttribution, outputDeadweight, outputDropOff, outputDisplacement, geocode, outputSdg, outputOwnFramework, project });
        await updateProject(project)
        actualOutcomeForm.reset();
        // updateLedger(ledger, project, inputTotalElement, outcomeTotalElement, npvElement, sroiElement);
        // await updateLedger(ledger, project, inputTotalElement, outcomeTotalElement, npvElement);
        await fetchDataAndUpdateUI(project, ledger)
    })
}

if (actualKpiForm) {
    const kpiLocation = document.getElementById('kpiLocation');
    const dropdownContainer = document.querySelector("#kpi-location-list");
    getLocation(kpiLocation, dropdownContainer, {
        dropdownClass: "input-actual-dropdown",
        listClass: "input-actual-dropdown-ul",
        itemClass: "input-actual-dropdown-item"
    });
    // const inputTotalElement = document.getElementById('inputActualTotal')
    // const outcomeTotalElement = document.getElementById('outcomeActualTotal')
    // const npvElement = document.getElementById('npvLedger');
    // const sroiElement = document.getElementById('actualSroiRatio');
    const project = document.getElementById('projectId').value;

    actualKpiForm.addEventListener('submit', async (e) => {
        e.preventDefault();
        const kpiQuantity = document.getElementById('kpiQuantity').value
        const kpi = document.getElementById('kpiId').value
        const kpiParentData = document.getElementById('kpiId')
        const selectedOption = kpiParentData.options[kpiParentData.selectedIndex];
        const kpiOrgParentId = selectedOption.dataset.kpiparentid;
        const kpiTitle = selectedOption.dataset.title;

        // (console.log(outputUnit))
        const date = document.getElementById('kpiDate').value
        let geocodeEl = document.getElementById('kpiLocation');
        let geocode;
        if (geocodeEl) {
            geocode = {
                locationId: geocodeEl.getAttribute('data-location-id'),
                lat: geocodeEl.getAttribute('data-lat'),
                lng: geocodeEl.getAttribute('data-lng')
            };
        }
        createKpiActual({ kpiQuantity, date, kpi, kpiTitle, kpiOrgParentId, geocode, project });
        await updateProject(project)
        actualKpiForm.reset();
        // updateLedger(ledger, project, inputTotalElement, outcomeTotalElement, npvElement, sroiElement);
        // updateLedger(ledger, project, inputTotalElement, outcomeTotalElement, npvElement);
        await fetchDataAndUpdateUI(project, ledger)
    })
}

if (ledger) {
    const projectId = document.getElementById('projectId').value
    // updateLedger(ledger, projectId, inputTotalElement, outcomeTotalElement, npvElement, sroiElement);
    // updateLedger(ledger, projectId, inputTotalElement, outcomeTotalElement, npvElement);
    fetchDataAndUpdateUI(projectId, ledger)
    const transactionElement = document.getElementById('movements');
    const formContainer = document.getElementById('formPopup')
    transactionElement.addEventListener('click', event => {
        const target = event.target;
        const formId = target.closest('[data-id]');
        const formType = target.closest('[data-type]');
        const formTitle = target.closest('[data-title]');
        const formCost = target.closest('[data-cost]');
        const formQuantity = target.closest('[data-quantity]');
        const formGeo = target.closest('[data-geo]');
        const formLat = target.closest('[data-lat]');
        const formLng = target.closest('[data-lng]');
        const formDate = target.closest('[data-date]');

        if (formId && formType) {
            const id = formId.getAttribute('data-id');
            const type = formType.getAttribute('data-type');
            const title = formTitle.getAttribute('data-title');
            if (type === 'input') {
                let updateCost = formCost.getAttribute('data-cost');
                let updateQuantity = formQuantity.getAttribute('data-quantity');
                let updateDateAttribute = formDate.getAttribute('data-date');
                let updateDate = moment(updateDateAttribute).format('YYYY-MM-DD');
                let updateGeo = formGeo.getAttribute('data-geo');
                let updateLat = formLat.getAttribute('data-lat');
                let updateLng = formLng.getAttribute('data-lng');
                transactionFormInput(id, title, type, updateCost, updateQuantity, updateGeo, updateLat, updateLng, updateDate, formContainer, projectId, ledger, inputTotalElement, outcomeTotalElement, npvElement, sroiElement);
            } else if (type === 'outcome') {
                let updateQuantity = formQuantity.getAttribute('data-quantity');
                let updateDateAttribute = formDate.getAttribute('data-date');
                let updateDate = moment(updateDateAttribute).format('YYYY-MM-DD');
                let updateGeo = formGeo.getAttribute('data-geo');
                let updateLat = formLat.getAttribute('data-lat');
                let updateLng = formLng.getAttribute('data-lng');
                transactionFormOutcome(id, title, type, updateQuantity, updateGeo, updateLat, updateLng, updateDate, formContainer, projectId, ledger, inputTotalElement, outcomeTotalElement, npvElement, sroiElement);
            } else if (type === 'kpi') {
                let updateQuantity = formQuantity.getAttribute('data-quantity');
                let updateDateAttribute = formDate.getAttribute('data-date');
                let updateDate = moment(updateDateAttribute).format('YYYY-MM-DD');
                let updateGeo = formGeo.getAttribute('data-geo');
                let updateLat = formLat.getAttribute('data-lat');
                let updateLng = formLng.getAttribute('data-lng');
                transactionFormKpi(id, title, type, updateQuantity, updateGeo, updateLat, updateLng, updateDate, formContainer, projectId, ledger, inputTotalElement, outcomeTotalElement, npvElement, sroiElement);
            }
        }
    });
};

if (myOrganisationUserDropdown) {
    // myOrganisationLead
    initialiseColleagues();
}

if (colleagueSettingsForm) {
    colleagueSettings();
}



// ADMIN
if (createOrganisationForm) {
    createOrganisationForm.addEventListener('submit', async (e) => {
        e.preventDefault()
        document.querySelector('.btn--create-organisation').textContent = 'Creating.....'

        const title = document.getElementById('organisationTitle').value
        const numLicences = document.getElementById('organisationLicences').value

        await createOrganisation({ title, numLicences })
    })
}

if (editOrganisationForm) {
    editOrganisationForm.addEventListener('submit', async (e) => {
        e.preventDefault()
        document.querySelector('.btn--edit-organisation').textContent = 'Editing...'

        const organisationId = document.getElementById('organisationId').value
        const title = document.getElementById('editOrganisationName').value

        await editOrganisation(organisationId, { title })
    })
}

if (orgEditFormContainer) {
    orgEditFormContainer.addEventListener('submit', async (e) => {
        e.preventDefault();
        const form = e.target;
        if (form.tagName === 'FORM' && form.classList.contains('org-edit-form')) {
            // Handle form submission...
            const formData = new FormData(form);
            const organisationId = formData.get('organisationId');
            const numLicences = formData.get('numLicences');
            const title = formData.get('orgName');
            try {
                const updatedOrg = await editOrganisationDetails(organisationId, { numLicences, title });
                // update the UI based on the success response
                const organisationElement = document.querySelector(`[data-organisation-id="${updatedOrg._id}"]`);
                if (organisationElement) {
                    const titleElement = organisationElement.querySelector('.org-title');
                    const numLicencesElement = organisationElement.querySelector('.num-licences-display');

                    titleElement.textContent = `${updatedOrg.title}`;
                    numLicencesElement.textContent = `${updatedOrg.numLicences}`;
                }
            } catch (error) {
                showAlert('error', 'An unexpected error occurred.');
            }
        }
    });


    // const forms = document.querySelectorAll('.org-edit-form');

    // forms.forEach((form) => {
    //     form.addEventListener('submit', async (e) => {
    //         e.preventDefault();
    //         alert('submitted')
    //         // Handle form submission...
    //         // const formData = new FormData(form);
    //         // const organisationId = formData.get('organisationId');

    //         // Your logic for submitting the form data...
    //     });
    // });

    // orgEditForm.addEventListener('submit', async (e) => {
    //     e.preventDefault();
    //     const orgFormData = new FormData(orgEditForm);
    //     const organisationId = orgFormData.get('organisationId')
    //     const numLicences = orgFormData.get('numLicences');
    //     try {
    //         const updatedOrg = await editOrganisationDetails(organisationId, { numLicences });
    //         console.log(updatedOrg);
    //         // await editOrganisationDetails(organisationId, formData);
    //         // Optionally update the UI based on the success response
    //     } catch (error) {
    //         showAlert('error', 'An unexpected error occurred.');
    //     }
    // })
}

if (sortOrganisationButton) {
    let sortByQuantity = true; // Flag to track the current sorting state
    const sortText = document.getElementById('sort--txt')
    sortOrganisationButton.addEventListener('click', () => {
        if (sortByQuantity) {
            sortOrganisationsByQuantity();
            sortText.textContent = 'SORT ALPHABETICALLY';
        } else {
            sortOrganisationsAlphabetically();
            sortText.textContent = 'SORT BY No OF USERS';
        }

        sortByQuantity = !sortByQuantity;

    });
    function sortOrganisationsByQuantity() {
        fetch('/api/v1/organisations/org-by-qty')
            .then(response => response.json())
            .then(sortedOrganisations => {
                // updateOrganisationList(sortedOrganisations);
                console.log(sortedOrganisations);
            })
            .catch(error => {
                console.error('Error sorting by quantity:', error);
            });
    }

    function sortOrganisationsAlphabetically() {
        fetch('/api/v1/organisations/org-by-qty') // Replace with the appropriate server route
            .then(response => response.json())
            .then(sortedOrganisations => {
                // updateOrganisationList(sortedOrganisations);
                console.log(sortedOrganisations);
            })
            .catch(error => {
                console.error('Error sorting by quantity:', error);
            });
    }
}

if (createSupplierAdminForm) {
    createSupplierAdminForm.addEventListener('submit', async (e) => {
        e.preventDefault()
        document.querySelector('.btn--create-admin-supplier').textContent = 'Creating.....'
        const title = document.getElementById('supplierName').value;
        const crn = document.getElementById('crn').value;
        const address = document.getElementById('address').value;
        const category = document.getElementById('supplierCategory').value;

        await createSupplierAdmin({ title, crn, address, category })
    })
}

if (editProxyForm) {
    editProxyForm.addEventListener('submit', async (e) => {
        e.preventDefault()
        document.querySelector('.btn--edit-proxy').textContent = 'Editing...'

        const proxyId = document.getElementById('proxyId').value
        const title = document.getElementById('editProxyTitle').value
        const bristol = document.getElementById('editProxyBristol').value
        const sdg = document.getElementById('editProxySDG').value
        const dg = document.getElementById('editProxyDG').value
        const barns = document.getElementById('editProxyBarns').value
        const value = document.getElementById('editProxyValue').value
        const outcome = document.getElementById('editProxyOutcome').value
        const unit = document.getElementById('editProxyUnit').value
        const anchor = document.getElementById('editProxyAnchor').value
        const source = document.getElementById('editProxySource').value
        const categories = document.getElementById('editProxyKeywords').value
        const regions = document.getElementById('editProxyRegion').value

        // console.log(title, value, outcome, unit, anchor, source, categories, regions)
        await editProxy(proxyId, { title, bristol, sdg, dg, barns, value, outcome, unit, anchor, source, categories, regions })
    })
}

if (deleteProxyForm) {
    deleteProxyForm.addEventListener('submit', async (e) => {
        e.preventDefault()
        document.querySelector('.btn--delete-proxy').textContent = 'Deleting...'
        let proxyId = document.getElementById('deleteProxyId').value

        await deleteProxy(proxyId)
    })
}

if (createProxyForm) {
    createProxyForm.addEventListener('submit', async (e) => {
        e.preventDefault()
        document.querySelector('.btn--create-proxy').textContent = 'Creating...'

        const title = document.getElementById('proxyTitle').value
        const bristol = document.getElementById('proxyBristol').value
        const value = document.getElementById('proxyValue').value
        const outcome = document.getElementById('proxyOutcome').value
        const unit = document.getElementById('proxyUnit').value
        const anchor = document.getElementById('proxyAnchor').value
        const source = document.getElementById('proxySource').value
        const categories = document.getElementById('proxyKeywords').value
        // const regions = document.getElementById('proxyRegion').value
        const regions = 'UK'
        const sdg = document.getElementById('proxySDG').value
        const dg = document.getElementById('proxyDG').value
        const barns = document.getElementById('proxyBarns').value

        await createProxy({ title, bristol, value, outcome, unit, anchor, source, categories, regions, sdg, dg, barns })
    })
}

if (createUserForm) {
    createUserForm.addEventListener('submit', async (e) => {
        e.preventDefault()
        document.querySelector('.btn--create-user').textContent = 'Creating...'

        const name = document.getElementById('userName').value
        const email = document.getElementById('userEmail').value
        const password = document.getElementById('userPassword').value
        const passwordConfirm = document.getElementById('userPasswordConfirm').value
        const organisation = document.getElementById('userOrganisation').value

        await createUser({ name, email, password, passwordConfirm, organisation })
    })
}

if (createSupplierForm) {
    let emailButtons = document.querySelectorAll('.email-supplier');
    emailButtons.forEach(function (button) {
        button.addEventListener('click', function (e) {
            e.stopPropagation();
            let name = this.getAttribute('data-name').split(' ')[0];
            let email = this.getAttribute('data-email');
            let subject = 'Social Value Monitoring'
            let site = 'https://socialvalueengine.com'
            let body = `Dear ${name}, \n\nPlease see login details for the social value engine below:\n\nusername: ${email}\n\npassword: \n\nYou can login at ${site}.\n\nPlease contact me with any questions. \n\nKind regards\n\n `;
            let mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

            // Open the user's default mail client with the prepopulated email data
            window.location.href = mailtoLink;
        })
    })
    createSupplierForm.addEventListener('submit', async (e) => {
        e.preventDefault()
        document.querySelector('.btn--create-supplier').textContent = 'Creating...'

        // const name = document.getElementById('userName').value
        // const email = document.getElementById('userEmail').value
        // const password = document.getElementById('userPassword').value
        // const passwordConfirm = document.getElementById('userPasswordConfirm').value
        // const organisationId = document.getElementById('organisationId').value
        // const supplier = document.getElementById('supplier').value
        // const crn = document.getElementById('crn').value
        // const address = document.getElementById('address').value

        // Separate variables for user data
        //     const userData = {
        //         name: document.getElementById('userName').value,
        //         email: document.getElementById('userEmail').value,
        //         password: document.getElementById('userPassword').value,
        //         passwordConfirm: document.getElementById('userPasswordConfirm').value,
        //         organisationId: document.getElementById('organisationId').value,
        //     };
        //     // Separate variables for supplier data
        //     const supplierData = {
        //         supplier: document.getElementById('supplier').value,
        //         crn: document.getElementById('crn').value,
        //         address: document.getElementById('address').value,
        //     };
        //     const combinedSupplierData = { ...userData, ...supplierData }
        //     console.log(combinedSupplierData)
        //     // await createSupplier({ name, email, password, passwordConfirm, organisationId, supplier, crn, address });
        //     await createSupplier(combinedSupplierData);
        //     createSupplierForm.reset();
        //     document.querySelector('.btn--create-supplier').textContent = 'Create User'
    })
}

if (editUserForm) {
    editUserForm.addEventListener('submit', async (e) => {
        e.preventDefault();
        document.querySelector('.btn--edit-user').textContent = 'Saving...'
        const userId = document.getElementById('userEditId').value
        const activeCheck = document.getElementById('active-toggle');
        const active = activeCheck.checked;
        // password confirm is working
        // await updateUser(userId, { active });
        await updateUser({ userId, active });
    })
}

// const headings = document.querySelectorAll('.accordion-heading');
// console.log(headings)
// headings.forEach(heading => {
//     heading.addEventListener('click', () => {
//         const content = heading.nextElementSibling;
//         if (content.style.display === 'block') {
//             content.style.display = 'none';
//         } else {
//             content.style.display = 'block';
//         }
//     });
// });
const headings = document.querySelectorAll('.accordion-heading');

headings.forEach(heading => {
    heading.addEventListener('click', () => {
        const content = heading.nextElementSibling;
        if (content.classList.contains('show')) {
            content.classList.remove('show');
        } else {
            content.classList.add('show');
        }
    });
});


// admin user search
if (searchUsers) {
    let userTemplate = document.getElementById('userTemplate').cloneNode(true)

    userTemplate.remove(); // Or on older browsers: template.parentNode.removeChild(template);
    userTemplate.removeAttribute('id');
    userTemplate.removeAttribute('style')

    searchUsers.addEventListener("keyup", async (e) => {
        const TIMEOUT = 100
        let timeoutId
        const searchUsers = e.target.value;
        clearTimeout(timeoutId)
        timeoutId = setTimeout(function () {
            const url = '/searchusers?searchText=' + searchUsers;
            fetch(url).then(response => response.json())
                // we are returning the correct users here
                .then(users => renderUsers(users))
        }, TIMEOUT)

        const renderUsers = function (users) {
            userContainer.innerHTML = ''
            for (const idx in users) {
                const user = users[idx];
                const userClone = userTemplate.cloneNode(true)
                const input = userClone.querySelector('.proxy-input')
                let lastActivity;
                if (user.lastActivity) {
                    lastActivity = moment(user.lastActivity).format('MMM Do YYYY')
                } else {
                    lastActivity = 'NA'
                }
                //populate the clone, set the values
                userClone.querySelector('.user-title').textContent = `${user.name}`
                userClone.querySelector('.user-email').textContent = `${user.email}`
                // userClone.querySelector('.user-createdAt').textContent = `${moment(user.createdAt).format('MMM Do YYYY')}`
                userClone.querySelector('.user-lastActivity').textContent = `${lastActivity}`
                userClone.querySelector('.user-organisation-title').textContent = `${user.organisation.title}`
                userClone.querySelector('.user-projectsCount').textContent = `${user.projectsCount}`
                userClone.querySelector('.user-active').textContent = `${user.active}`
                userClone.querySelector('.user-id').innerHTML = `<a href=/colleague/${user._id}/all/page/1>View</a>`

                userContainer.appendChild(userClone)
            }
        }
    })
}


if (reportTable) {
    document.querySelectorAll('.dropbtn').forEach(item => {
        item.addEventListener('mouseenter', async (e) => {
            e.currentTarget.parentNode.querySelector('.dropdown-years').classList.toggle('show')
        })
        item.addEventListener('mouseleave', async (e) => {
            e.currentTarget.parentNode.querySelector('.dropdown-years').classList.toggle('show')
        })
    })
}

if (reportTable) {
    document.querySelectorAll('.dropbtn-subgroup').forEach(item => {
        item.addEventListener('mouseenter', async (e) => {
            e.currentTarget.parentNode.querySelector('.dropdown-subgroups').classList.toggle('show')
        })
        item.addEventListener('mouseleave', async (e) => {
            e.currentTarget.parentNode.querySelector('.dropdown-subgroups').classList.toggle('show')
        })
    })
}

if (pieChart) {
    const frameworkDataInput = document.getElementById('framework-data');
    const frameworkData = JSON.parse(frameworkDataInput.value);
    // const frameworkData = frameworkDataInput.value;
    const colourMapping = {
        1: '#E6243C',
        2: '#DEA63A',
        3: '#4F9D39',
        4: '#C5192D',
        5: '#FE3922',
        6: '#25BDE2',
        7: '#FCC20C',
        8: '#A31A41',
        9: '#FE6727',
        10: '#DE1369',
        11: '#FF9B26',
        12: '#BE8C31',
        13: '#417C46',
        14: '#0C97DB',
        15: '#57C029',
        16: '#01689D',
        17: '#184769'
    };
    pieChart.getContext('2d')

    Chart.defaults.global.defaultFontFamily = 'Lato'
    Chart.defaults.global.defaultFontSize = 12
    Chart.defaults.global.defaultFontColor = '#777'
    // Chart.defaults.global.tooltipTemplate = function (label) {
    //     return label + ': ' + label.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    // };

    let impactArea = []
    let impactAreaTotals = []
    let impactAreaColours = []

    if (project.framework === 'Bristol Accord') {
        project.outputCategoriesTotal.forEach(bristol => {
            switch (bristol._id) {
                case 1:
                    impactArea.push('Active, Inclusive and Safe')
                    impactAreaTotals.push(bristol.total.toFixed(2))
                    impactAreaColours.push('#3a66b7')
                    break
                case 2:
                    impactArea.push('Well Run')
                    impactAreaTotals.push(bristol.total.toFixed(2))
                    impactAreaColours.push('#f05')
                    break
                case 3:
                    impactArea.push('Environment')
                    impactAreaTotals.push(bristol.total.toFixed(2))
                    impactAreaColours.push('#81c836')
                    break
                case 4:
                    impactArea.push('Well Designed and Built')
                    impactAreaTotals.push(bristol.total.toFixed(2))
                    impactAreaColours.push('#5e7492')
                    break
                case 5:
                    impactArea.push('Well Connected')
                    impactAreaTotals.push(bristol.total.toFixed(2))
                    impactAreaColours.push('#ffc100')
                    break
                case 6:
                    impactArea.push('Fair to Everyone')
                    impactAreaTotals.push(bristol.total.toFixed(2))
                    impactAreaColours.push('#649a40')
                    break
                case 7:
                    impactArea.push('Thriving')
                    impactAreaTotals.push(bristol.total.toFixed(2))
                    impactAreaColours.push('#bb0')
                    break
                case 8:
                    impactArea.push('Well Served')
                    impactAreaTotals.push(bristol.total.toFixed(2))
                    impactAreaColours.push('#6d6d6d')
                    break
                case 9:
                    impactArea.push('National TOMS Framework')
                    impactAreaTotals.push(bristol.total.toFixed(2))
                    impactAreaColours.push('#13a264')
                    break
                case 10:
                    impactArea.push('User Own')
                    impactAreaTotals.push(bristol.total.toFixed(2))
                    impactAreaColours.push('#ffa500')
                    break
                default:
                    impactArea.push('Other')
                    impactAreaTotals.push(bristol.total.toFixed(2))
                    impactAreaColours.push('#ffa500')
            }
        })
    } else if (project.framework === 'Sustainable Development Goals') {
        project.outputSDGTotal.forEach(sdg => {
            switch (sdg._id) {
                case 1:
                    impactArea.push('No Poverty')
                    impactAreaTotals.push(sdg.total.toFixed(2))
                    impactAreaColours.push('#E6243C')
                    break
                case 2:
                    impactArea.push('Zero Hunger')
                    impactAreaTotals.push(sdg.total.toFixed(2))
                    impactAreaColours.push('#DEA63A')
                    break
                case 3:
                    impactArea.push('Good Health and Well-Being')
                    impactAreaTotals.push(sdg.total.toFixed(2))
                    impactAreaColours.push('#4F9D39')
                    break
                case 4:
                    impactArea.push('Quality Education')
                    impactAreaTotals.push(sdg.total.toFixed(2))
                    impactAreaColours.push('#C5192D')
                    break
                case 5:
                    impactArea.push('Gender Equality')
                    impactAreaTotals.push(sdg.total.toFixed(2))
                    impactAreaColours.push('#FE3922')
                    break
                case 6:
                    impactArea.push('Clean Water and Sanitation')
                    impactAreaTotals.push(sdg.total.toFixed(2))
                    impactAreaColours.push('#25BDE2')
                    break
                case 7:
                    impactArea.push('Affordable and Clean Energy')
                    impactAreaTotals.push(sdg.total.toFixed(2))
                    impactAreaColours.push('#FCC20C')
                    break
                case 8:
                    impactArea.push('Decent Work and Economic Growth')
                    impactAreaTotals.push(sdg.total.toFixed(2))
                    impactAreaColours.push('#A31A41')
                    break
                case 9:
                    impactArea.push('Industry, Innovation and Infrastructure')
                    impactAreaTotals.push(sdg.total.toFixed(2))
                    impactAreaColours.push('#FE6727')
                    break
                case 10:
                    impactArea.push('Reduced Inequalities')
                    impactAreaTotals.push(sdg.total.toFixed(2))
                    impactAreaColours.push('#DE1369')
                    break
                case 11:
                    impactArea.push('Sustainable Cities and Communities')
                    impactAreaTotals.push(sdg.total.toFixed(2))
                    impactAreaColours.push('#FF9B26')
                    break
                case 12:
                    impactArea.push('Responsible Consumption and Production')
                    impactAreaTotals.push(sdg.total.toFixed(2))
                    impactAreaColours.push('#BE8C31')
                    break
                case 13:
                    impactArea.push('Climate Action')
                    impactAreaTotals.push(sdg.total.toFixed(2))
                    impactAreaColours.push('#417C46')
                    break
                case 14:
                    impactArea.push('Life Below Water')
                    impactAreaTotals.push(sdg.total.toFixed(2))
                    impactAreaColours.push('#0C97DB')
                    break
                case 15:
                    impactArea.push('Life On Land')
                    impactAreaTotals.push(sdg.total.toFixed(2))
                    impactAreaColours.push('#57C029')
                    break
                case 16:
                    impactArea.push('Peace, Justice and Strong Institutions')
                    impactAreaTotals.push(sdg.total.toFixed(2))
                    impactAreaColours.push('#01689D')
                    break
                case 17:
                    impactArea.push('Partnerships for the Goals')
                    impactAreaTotals.push(sdg.total.toFixed(2))
                    impactAreaColours.push('#184769')
                    break
                default:
                    impactArea.push('Other')
                    impactAreaTotals.push(sdg.total.toFixed(2))
                    impactAreaColours.push('#007569')
            }
        })
    } else if (project.framework === 'Dumfries and Galloway') {
        project.outputDGTotal.forEach(dg => {
            switch (dg._id) {
                case 1:
                    impactArea.push('Build the local economy')
                    impactAreaTotals.push(dg.total.toFixed(2))
                    impactAreaColours.push('#3a66b7')
                    break
                case 2:
                    impactArea.push('Provide the best start in life for all our children')
                    impactAreaTotals.push(dg.total.toFixed(2))
                    impactAreaColours.push('#f05')
                    break
                case 3:
                    impactArea.push('Protect our most vulnerable people')
                    impactAreaTotals.push(dg.total.toFixed(2))
                    impactAreaColours.push('#81c836')
                    break
                case 4:
                    impactArea.push('Be an inclusive council')
                    impactAreaTotals.push(dg.total.toFixed(2))
                    impactAreaColours.push('#5e7492')
                    break
                case 5:
                    impactArea.push('Climate change and carbon neutral region')
                    impactAreaTotals.push(dg.total.toFixed(2))
                    impactAreaColours.push('#ffc100')
                    break
                case 6:
                    impactArea.push('Other')
                    impactAreaTotals.push(dg.total.toFixed(2))
                    impactAreaColours.push('#3a66b7')
                    break
                case 7:
                    impactArea.push('Other')
                    impactAreaTotals.push(dg.total.toFixed(2))
                    impactAreaColours.push('#f05')
                    break
                case 8:
                    impactArea.push('Other')
                    impactAreaTotals.push(dg.total.toFixed(2))
                    impactAreaColours.push('#81c836')
                    break
                case 9:
                    impactArea.push('Other')
                    impactAreaTotals.push(dg.total.toFixed(2))
                    impactAreaColours.push('#5e7492')
                    break
                case 10:
                    impactArea.push('Other')
                    impactAreaTotals.push(dg.total.toFixed(2))
                    impactAreaColours.push('#ffc100')
                    break
                case 11:
                    impactArea.push('Other')
                    impactAreaTotals.push(dg.total.toFixed(2))
                    impactAreaColours.push('#ffc100')
                    break
                case 12:
                    impactArea.push('Other')
                    impactAreaTotals.push(dg.total.toFixed(2))
                    impactAreaColours.push('#ffc100')
                    break
                case 13:
                    impactArea.push('Other')
                    impactAreaTotals.push(dg.total.toFixed(2))
                    impactAreaColours.push('#ffc100')
                    break
                case 14:
                    impactArea.push('Other')
                    impactAreaTotals.push(dg.total.toFixed(2))
                    impactAreaColours.push('#ffc100')
                    break
                case 15:
                    impactArea.push('Other')
                    impactAreaTotals.push(dg.total.toFixed(2))
                    impactAreaColours.push('#ffc100')
                    break
                case 16:
                    impactArea.push('Other')
                    impactAreaTotals.push(dg.total.toFixed(2))
                    impactAreaColours.push('#ffc100')
                    break
                case 17:
                    impactArea.push('Other')
                    impactAreaTotals.push(dg.total.toFixed(2))
                    impactAreaColours.push('#ffc100')
                    break
                case 18:
                    impactArea.push('Other')
                    impactAreaTotals.push(dg.total.toFixed(2))
                    impactAreaColours.push('#ffc100')
                    break
                case 19:
                    impactArea.push('Other')
                    impactAreaTotals.push(dg.total.toFixed(2))
                    impactAreaColours.push('#ffc100')
                    break
                case 20:
                    impactArea.push('Other')
                    impactAreaTotals.push(dg.total.toFixed(2))
                    impactAreaColours.push('#ffc100')
                    break
                case 21:
                    impactArea.push('Other')
                    impactAreaTotals.push(dg.total.toFixed(2))
                    impactAreaColours.push('#ffc100')
                    break
                default:
                    impactArea.push('Other')
                    impactAreaTotals.push(dg.total.toFixed(2))
                    impactAreaColours.push('#ffa500')
            }
        })
    } else if (project.framework === 'Barnsley') {
        project.outputBarnsTotal.forEach(barn => {
            switch (barn._id) {
                case 1:
                    impactArea.push('Growing Barnsley')
                    impactAreaTotals.push(barn.total.toFixed(2))
                    impactAreaColours.push('#3a66b7')
                    break
                case 2:
                    impactArea.push('Healthy Barnsley')
                    impactAreaTotals.push(barn.total.toFixed(2))
                    impactAreaColours.push('#f05')
                    break
                case 3:
                    impactArea.push('Learning Barnsley')
                    impactAreaTotals.push(barn.total.toFixed(2))
                    impactAreaColours.push('#81c836')
                    break
                case 4:
                    impactArea.push('Sustainable Barnsley')
                    impactAreaTotals.push(barn.total.toFixed(2))
                    impactAreaColours.push('#5e7492')
                    break
                default:
                    impactArea.push('Other')
                    impactAreaTotals.push(barn.total.toFixed(2))
                    impactAreaColours.push('#ffa500')
            }
        })
    } else if (project.framework === 'own_framework') {
        project.outputOwnFrameworkTotal.forEach(priority => {
            const matchingFramework = frameworkData.find(framework => framework._id === priority._id);
            const description = matchingFramework ? matchingFramework.description : 'Other';
            const colour = colourMapping[priority._id] || '#007569';
            impactArea.push(description);
            impactAreaTotals.push(priority.total.toFixed(2));
            impactAreaColours.push(colour);
        });
    }


    let bristolChart = new Chart(pieChart, {
        type: 'pie',
        data: {
            labels: impactArea,
            datasets: [{
                label: 'Bristol Accord',
                data: impactAreaTotals,
                backgroundColor: impactAreaColours,
                borderWidth: 1,
                // borderColor: `#777`,
                borderColor: `white`,
                hoverBorderWidth: 1,
                // hoverBorderColor: '#007569'
                hoverBorderColor: '#777'
            }]
        },
        options: {
            title: {
                display: true,
                text: 'IMPACT AREA'
            },
            legend: {
                position: 'right',
                labels: {
                    fontColor: '#777'
                }
            },
            layout: {
                padding: {
                    left: 0,
                    right: 50,
                    bottom: 0,
                    top: 0
                }
            },
            tooltips: {
                enabled: true,
                callbacks: {
                    label: function (tooltipItems, data) {
                        return ' ' + data.labels[tooltipItems.index] + ': £' + data.datasets[0].data[tooltipItems.index].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                }
            }
        }
    })
}

if (comparisonCalcBtn) {
    comparisonCalcBtn.addEventListener('click', async (e) => {
        e.preventDefault()
        comparisonCalcBtn.textContent = 'Calculating...';
        // NEED TO LOOK AT THIS FUNCTION BELOW! IT'S RUNNING 4 TIMES, WHEN IT SHOULD JUST BE updateOutput(id, {q1, q2, q3, q4})
        const elements = document.querySelectorAll('input[data-id="quarters"]');
        const QsPerDoc = [];

        elements.forEach(e => {
            const docId = e.getAttribute('id');
            const val = e.value;
            const quarter = e.className.substring(6);
            const entry = QsPerDoc.find(e => e.docId === docId);

            // find returns undefined if nothing's found. undefined is a falsy value
            if (!entry) {
                let quarters = {};
                quarters[quarter] = val;

                QsPerDoc.push({
                    docId: docId,
                    quarters
                });
            }
            else {
                entry.quarters[quarter] = val;
            }
        });

        await Promise.all(Array.from(QsPerDoc).map(async (e) => {
            await updateOutput(e.docId, { q1: e.quarters.q1, q2: e.quarters.q2, q3: e.quarters.q3, q4: e.quarters.q4, });
        }));

        await updateProject(project.id)
        window.setTimeout(() => {
            location.reload()
        }, 500)
    })
}

if (supplierUsersList) {
    // createSupplierUserForm
    // add the existing supplier user to the organisation
    document.querySelectorAll('.btn-add-supplier-user-to-org').forEach(btn => {
        btn.addEventListener('click', function (e) {
            e.preventDefault();
            const supplierUserId = btn.getAttribute('data-supplier-user-id');
            addSupplierUserToOrg({ supplierUserId });
        })
    })
    // create a new supplier user button
    const formContainer = document.getElementById('createSupplierUserFormDiv')
    document.querySelectorAll('.btn-create-supplier-user').forEach(btn => {
        btn.addEventListener('click', function (e) {
            e.preventDefault();
            const supplierUserId = btn.getAttribute('data-supplier-id');
            const supplierName = btn.getAttribute('data-supplier-name');
            createNewSupplierUserForm(formContainer, supplierUserId, supplierName);
        })
    })
    const removeFormContainer = document.getElementById('removeSupplierUserFormDiv')
    document.querySelectorAll('.btn--remove-supplier-user').forEach(button => {
        button.addEventListener('click', function () {
            const supplierUserId = button.getAttribute('data-key');
            const supplierUserName = button.getAttribute('data-supplier-user-name');
            const userOrganisation = button.getAttribute('data-organisation');
            removeSupplierUser(removeFormContainer, supplierUserId, supplierUserName, userOrganisation)
        })
    })
}

if (mapCanvas) {
    ///////////////////////////////////////
    // MAPP ARCHITECTURE
    const mapForm = document.querySelector('.map__form');
    const projectDropdown = document.getElementById('map__project');
    const forecastDropdown = document.getElementById('map__forecast');
    const typeDropdown = document.getElementById('map__type');
    const containerTransactions = document.querySelector('.map__transactions');
    const loadMapEl = document.getElementById('loadMapData');
    const latitude = loadMapEl.dataset.lat;
    const longitude = loadMapEl.dataset.lng;
    const orgId = document.getElementById('orgid').value;
    const resetButton = document.getElementById('map--reset__btn');


    // Show the loading indicator
    const loadingIndicator = document.getElementById('loading-indicator');
    const loadingOverlay = document.getElementById('loading-overlay');
    loadingIndicator.style.display = 'flex';
    loadingOverlay.style.opacity = '1';
    loadingOverlay.style.visibility = 'visible';

    const config = {
        mapForm: mapForm,
        latitude: latitude,
        longitude: longitude,
        zoomLevel: 13
    };
    const mapp = new Mapp(config);
    mapp._getPosition(config.latitude, config.longitude);
    containerTransactions.addEventListener('click', mapp._moveToPopup.bind(mapp));
    const TIMEOUT = 100
    let timeoutId
    clearTimeout(timeoutId)
    timeoutId = setTimeout(function () {
        const url = '/api/v1/organisations/mapdocs/' + orgId;
        fetch(url)
            .then(response => response.json())
            .then(docs => displayTransactions(docs));
    }, TIMEOUT)
    const displayTransactions = function (docs) {
        const projectDropdown = document.getElementById('map__project');

        // Clear existing options
        projectDropdown.innerHTML = '';
        const allOption = document.createElement('option');
        allOption.value = 'all';
        allOption.textContent = 'All';
        projectDropdown.appendChild(allOption);
        // Iterate over the list of projects for the select dropdown
        docs.forEach((project) => {
            const option = document.createElement('option');
            option.value = project._id;
            option.textContent = project.name;
            projectDropdown.appendChild(option);
        });
        const transactions = docs.flatMap(project => {
            const inputs = project.inputs ? project.inputs.map(input => new Input(input.id, input.title, project.createdAt, input.impact, input.geocode, project.id, project.name)) : [];
            const outputs = project.outputs ? project.outputs.map(output => new Outcome(output.id, output.title, project.createdAt, output.impact, output.geocode, project.id, project.name)) : [];
            const outcomeActuals = project.outcomeActuals ? project.outcomeActuals.map(outcomeActual => new OutcomeActual(outcomeActual.id, outcomeActual.outputTitle, outcomeActual.date, outcomeActual.impact, outcomeActual.geocode, project.id, project.name)) : [];
            const inputActuals = project.inputActuals ? project.inputActuals.map(inputActual => new InputActual(inputActual.id, inputActual.inputTitle, inputActual.date, inputActual.impact, inputActual.geocode, project.id, project.name)) : [];
            const kpis = project.kpis.map(kpi => new Kpi(kpi.id, kpi.kpiTitle, kpi.date, kpi.kpiForecast, kpi.geocode, project.id, project.name));
            const kpiActuals = project.kpiActuals ? project.kpiActuals.map(kpiActual => new KpiActual(kpiActual.id, kpiActual.kpiTitle, kpiActual.date, kpiActual.kpiQuantity, kpiActual.geocode, project.id, project.name)) : [];
            // console.log(kpiActuals)
            // combine the childdocs together into an array
            const childDocs = inputs.concat(outputs, outcomeActuals, inputActuals, kpis, kpiActuals);
            // display in the UI
            childDocs.forEach(childDoc => {
                mapp._renderTransaction(childDoc, mapForm); // Render HTML element
                // mapp._renderTransactionMarker(childDoc);
                mapp.transactions.push(childDoc);
                if (childDoc.coords && isValidCoordinate(childDoc.coords.lat, childDoc.coords.lng)) {
                    // mapp.transactions.push(childDoc);
                    const lat = parseFloat(childDoc.coords.lat);
                    const lng = parseFloat(childDoc.coords.lng);
                    mapp._renderTransactionMarker(childDoc, lat, lng);
                }
            });
        });
        loadingOverlay.style.visibility = 'hidden';
        loadingOverlay.style.opacity = '0';
        loadingOverlay.style.display = 'none';
        loadingIndicator.style.display = 'none';

        containerTransactions.addEventListener('click', mapp._moveToPopup.bind(mapp));
    }

    function isValidCoordinate(lat, lng) {
        // Check if lat and lng are valid numbers within a valid range
        return typeof lat === 'number' && !isNaN(lat) && typeof lng === 'number' && !isNaN(lng);
    }
    containerTransactions.addEventListener('click', mapp._moveToPopup.bind(mapp));
    projectDropdown.addEventListener('change', filterData);
    forecastDropdown.addEventListener('change', filterData);
    typeDropdown.addEventListener('change', filterData);
    // function filterData() {
    //     const selectedProjectId = projectDropdown.value;
    //     const selectedForecast = forecastDropdown.value;
    //     const selectedType = typeDropdown.value;

    //     mapp._clearMapMarkers()

    //     const mapMarkers = mapp.transactions;
    //     // console.log(mapMarkers)
    //     mapMarkers.forEach(marker => {
    //         // console.log(marker)
    //         const transactionId = marker.transactionId;
    //         const markerProjectId = marker.projectid;
    //         const markerForecast = marker.forecast;
    //         const markerType = marker.type;
    //         if (
    //             (selectedProjectId === 'all' || markerProjectId === selectedProjectId) &&
    //             (selectedForecast === 'all' || markerForecast === selectedForecast) &&
    //             (selectedType === 'all' || markerType === selectedType)
    //         ) {
    //             // marker.addTo(this.mapp); // Show the marker
    //             const lat = parseFloat(marker.coords.lat);
    //             const lng = parseFloat(marker.coords.lng);
    //             mapp._renderTransactionMarker(marker, lat, lng);
    //         }
    //     });
    //     // Filter the HTML DOM elements based on the selected values
    //     // You can use techniques like hiding/showing elements or removing/adding elements from the DOM
    //     // const domElements = document.querySelectorAll('.transaction');
    //     // domElements.forEach(element => {
    //     mapp.transactions.forEach(transaction => {
    //         // filter the DOM elements based on the dropdowns
    //         // containerTransactions.innerHTML = '';
    //         const transactionElements = containerTransactions.getElementsByClassName('transaction');

    //         // Remove existing transaction elements
    //         while (transactionElements.length > 0) {
    //             containerTransactions.removeChild(transactionElements[0]);
    //         }
    //         if (
    //             // (selectedProjectId === 'all' || element.dataset.projectid === selectedProjectId) &&
    //             // (selectedForecast === 'all' || element.dataset.forecast === selectedForecast) &&
    //             // (selectedType === 'all' || element.dataset.type === selectedType)
    //             (selectedProjectId === 'all' || transaction.projectid === selectedProjectId) &&
    //             (selectedForecast === 'all' || transaction.forecast === selectedForecast) &&
    //             (selectedType === 'all' || transaction.type === selectedType)
    //         ) {
    //             // element.style.display = 'grid'; // Show the element
    //             // mapp._renderTransaction(transaction, containerTransactions);
    //         } else {
    //             // element.style.display = 'none'; // Hide the element
    //             // console.log('else')
    //         }
    //     });
    // }

    function filterData() {
        const selectedProjectId = projectDropdown.value;
        const selectedForecast = forecastDropdown.value;
        const selectedType = typeDropdown.value;

        mapp._clearMapMarkers();

        const mapMarkers = mapp.transactions;
        mapMarkers.forEach(marker => {
            const transactionId = marker.transactionId;
            const markerProjectId = marker.projectid;
            const markerForecast = marker.forecast;
            // const markerType = marker.type;
            // const markerType = marker.type === 'inputActual' ? 'input' : (marker.type === 'outcomeActual' ? 'outcome' : marker.type);
            const markerType =
                marker.type === 'inputActual' ? 'input' :
                    marker.type === 'outcomeActual' ? 'outcome' :
                        marker.type === 'kpiActual' ? 'kpi' :
                            marker.type;

            if (
                (selectedProjectId === 'all' || markerProjectId === selectedProjectId) &&
                (selectedForecast === 'all' || markerForecast === selectedForecast) &&
                (selectedType === 'all' || markerType === selectedType) &&
                marker.coords && marker.coords.lat && marker.coords.lng
            ) {
                console.log(marker)
                const lat = parseFloat(marker.coords.lat);
                const lng = parseFloat(marker.coords.lng);
                mapp._renderTransactionMarker(marker, lat, lng);
            }
        });

        const transactionElements = containerTransactions.getElementsByClassName('transaction');

        Array.from(transactionElements).forEach(transactionElement => {
            if (
                (selectedProjectId === 'all' || transactionElement.dataset.projectid === selectedProjectId) &&
                (selectedForecast === 'all' || transactionElement.dataset.forecast === selectedForecast) &&
                (selectedType === 'all' || transactionElement.dataset.type === selectedType)
            ) {
                transactionElement.style.display = 'grid'; // Show the element
            } else {
                transactionElement.style.display = 'none'; // Hide the element
            }
        });
    }

    resetButton.addEventListener('click', resetDropdowns);

    function resetDropdowns() {
        projectDropdown.value = 'all'; // Replace 'all' with the original default value
        forecastDropdown.value = 'all'; // Replace 'all' with the original default value
        typeDropdown.value = 'all'; // Replace 'all' with the original default value

        // Call your filterData function here to apply the filters with the reset values
        filterData();
    }
    function initialise() {
        // Add event listeners to the dropdowns
        projectDropdown.addEventListener('change', filterData);
        forecastDropdown.addEventListener('change', filterData);
        typeDropdown.addEventListener('change', filterData);

        // Fetch data and display the initial transactions
        // fetch('/api/v1/organisations/mapdocs/' + orgId)
        //     .then(response => response.json())
        //     .then(docs => displayTransactions(docs));
    }
    initialise();
}

// if (bookBtn) {
//     bookBtn.addEventListener('click', (e) => {
//         e.target.textContent = 'Processing...'
//         const licenceId = '5dd2456b23ae29f06b05770a'
//         buyLicence(licenceId)
//     })
// }

